import { gql, useQuery } from "@apollo/client";
import { SundialDayData } from "../gql/graphql";
import { useMemo, useState } from "react";
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  YAxis,
} from "recharts";
import { InfoCircle } from "iconsax-react";
import interpolate from "color-interpolate";

const GET_SUNDIAL_HISTORY = gql`
  query GetSundialHistory($localTz: String!) {
    getSundialHistory(request: { localTz: $localTz }) {
      days {
        date
        sundialScore
      }
    }
  }
`;

type ChartDataPoint = {
  dayPercentage: number;
  date: Date;
};

const colormap = interpolate(["#25536D", "#E5C852"]);

export function SundialHistoryChart() {
  const { data: sundialData } = useQuery(GET_SUNDIAL_HISTORY, {
    variables: { localTz: Intl.DateTimeFormat().resolvedOptions().timeZone },
  });
  const sundialChartState: {
    data: ChartDataPoint[];
    darkStartingColor: string;
    lightStartingColor: string;
    gradientMidpoint: number;
  } = useMemo(() => {
    if (sundialData) {
      const sundialDayData: SundialDayData[] =
        sundialData.getSundialHistory.days;
      const maxDayPercentage = Math.max(
        ...sundialDayData.map((day) => day.sundialScore)
      );
      const maxNightPercentage =
        -1 * Math.min(...sundialDayData.map((day) => day.sundialScore));
      const darkStartingColor = colormap((1 - maxNightPercentage) / 2);
      const lightStartingColor = colormap((1 + maxDayPercentage) / 2);
      const gradientMidpoint =
        maxNightPercentage / (maxNightPercentage + maxDayPercentage);
      return {
        data: [
          ...sundialDayData.map((day) => ({
            dayPercentage: day.sundialScore * 100,
            date: new Date(day.date),
          })),
        ],
        darkStartingColor,
        lightStartingColor,
        gradientMidpoint,
      };
    }
    return {
      data: [],
      darkStartingColor: "#25536D",
      lightStartingColor: "#E5C852",
      gradientMidpoint: 0.5,
    };
  }, [sundialData]);
  const latestScore =
    sundialChartState.data.length > 0
      ? sundialChartState.data[sundialChartState.data.length - 1].dayPercentage
      : 0;
  const [infoHovered, setInfoHovered] = useState(false);

  if (!sundialData) {
    return null;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row items-center relative">
        <p className="font-sans">
          {latestScore >= 0 ? "Day" : "Night"} ·{" "}
          {Math.abs(Math.round(latestScore))}%
        </p>
        <div
          style={{ marginLeft: 12 }}
          onMouseEnter={() => {
            setInfoHovered(true);
          }}
          onMouseLeave={() => {
            setInfoHovered(false);
          }}
        >
          <InfoCircle size={12} />
        </div>
        {infoHovered && (
          <div
            className="absolute"
            style={{
              right: -70,
              top: 30,
              backgroundColor: "white",
              zIndex: 1,
              padding: 12,
              width: 200
            }}
          >
            <p className="font-sans" style={{ fontSize: 12 }}>
              Here's where you can see how much writing happens on Draft Zero
              during the day and night over the past 60 days. The later people
              write at night, the more it shifts toward night, and vice
              versa.
            </p>
          </div>
        )}
      </div>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart
          width={200}
          height={200}
          data={sundialChartState.data.map(function (x, i) {
            return {
              time: x.date,
              dayPercentage: x.dayPercentage,
              nightPercentage: -1 * x.dayPercentage,
            };
          })}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          style={{ cursor: "pointer" }}
        >
          <defs>
            <linearGradient id="lgrad" x1="50%" y1={`100%`} x2="50%" y2={`0%`}>
              <stop
                offset={`${sundialChartState.gradientMidpoint - 0.25}`}
                stopColor={sundialChartState.darkStartingColor}
                stopOpacity={1}
              />
              <stop
                offset={`${sundialChartState.gradientMidpoint}`}
                stopColor={colormap(0.5)}
                stopOpacity={1}
              />
              <stop
                offset={`${sundialChartState.gradientMidpoint + 0.25}`}
                stopColor={sundialChartState.lightStartingColor}
                stopOpacity={1}
              />
            </linearGradient>
          </defs>
          <YAxis domain={[-100, 100]} tick={false} />
          <Area
            dataKey="dayPercentage"
            stroke="#000000"
            dot={false}
            fillOpacity={1}
            fill="url(#lgrad)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
