import { Button } from "@nextui-org/react";
import Logo from "../assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";

function UnauthenticatedHomePageHeader() {
  const location = useLocation();
  const isHome = useMemo(() => location.pathname === "/", [location.pathname]);
  const isSmallMobile = useMediaQuery({
    query: "(max-width: 400px)",
  });
  return (
    <>
      <div
        className="w-full flex flex-row justify-between py-2 items-center border-b border-solid border-black px-3 fixed"
        style={{ backgroundColor: "white", zIndex: 1000 }}
      >
        {isHome ? (
          <div className="flex flex-row items-center cursor-default font-semibold">
            <img src={Logo} alt="logo" style={{ height: 40 }} />
            <p
              className="ml-2"
              style={{
                fontFamily: "Nothing You Could Do",
                fontSize: 20,
              }}
            >
              draft zero
            </p>
          </div>
        ) : (
          <Link to="/">
            <div className="flex flex-row items-center font-semibold">
              <img src={Logo} alt="logo" style={{ height: 40 }} />
              <p
                className="ml-2"
                style={{ fontFamily: "Nothing You Could Do", fontSize: 20 }}
              >
                draft zero
              </p>
            </div>
          </Link>
        )}
        <div className="font-sans flex flex-row">
          <div style={{ marginRight: 10 }}>
            <Link to="/login">
              <Button size={isSmallMobile ? "sm" : "md"}>Login</Button>
            </Link>
          </div>
          <Link to="/signup">
            <Button color="primary" size={isSmallMobile ? "sm" : "md"}>
              Sign up
            </Button>
          </Link>
        </div>
      </div>
      <div style={{ height: 57 }}></div>
    </>
  );
}

export default UnauthenticatedHomePageHeader;
