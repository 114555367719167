import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useCallback, useEffect } from "react";
import {
  incrementWordsAdded,
  incrementWordsDeleted,
  setChartData,
  setDataFromLoadedActivity,
  setOutOfSync,
  setTitleAndDescription,
  setUnloaded,
  updateChecksum,
  pauseRecording,
  resetRecording,
  startRecording,
  finishRecording,
} from "../redux/activityStore";
import { useDebouncedCallback } from "use-debounce";
import { ActivityStatus, FinishActivityInput } from "../gql/graphql";

const GET_ACTIVITY_FOR_USER = gql`
  query getActivityForUser {
    getActivityForUser {
      activityID
      wordsAdded
      wordsDeleted
      secondsElapsed
      status
      title
      description
      checksum
    }
  }
`;

const INCREMENT_ACTIVITY = gql`
  mutation incrementActivity(
    $secondsElapsed: Int!
    $wordsAdded: Int!
    $wordsDeleted: Int!
    $checksum: String!
  ) {
    incrementActivityData(
      request: {
        secondsElapsed: $secondsElapsed
        wordsAdded: $wordsAdded
        wordsDeleted: $wordsDeleted
        checksum: $checksum
      }
    ) {
      checksum
    }
  }
`;

const GET_CHART_DATA = gql`
  query GetChartData($activityID: String!) {
    getActivityLineData(request: { activityID: $activityID }) {
      activityID
      secondsElapsedArray
      wordDifferentialArray
    }
  }
`;

const MODIFY_FINISHED_ACTIVITY = gql`
  mutation modifyFinishedActivity(
    $activityID: String!
    $title: String
    $description: String
    $checksum: String!
  ) {
    modifyFinishedActivity(
      request: {
        activityID: $activityID
        title: $title
        description: $description
        checksum: $checksum
      }
    ) {
      checksum
    }
  }
`;

const PAUSE_ACTIVITY = gql`
  mutation PauseActivity(
    $secondsElapsed: Int!
    $wordsAdded: Int!
    $wordsDeleted: Int!
    $checksum: String!
  ) {
    pauseActivity(
      request: {
        secondsElapsed: $secondsElapsed
        wordsAdded: $wordsAdded
        wordsDeleted: $wordsDeleted
        checksum: $checksum
      }
    ) {
      checksum
    }
  }
`;

const UNPAUSE_ACTIVITY = gql`
  mutation UnpauseActivity($checksum: String!) {
    unpauseActivity(request: { checksum: $checksum }) {
      checksum
    }
  }
`;

const FINISH_ACTIVITY = gql`
  mutation FinishActivity(
    $finalSecondsElapsed: Int!
    $finalWordsAdded: Int!
    $finalWordsDeleted: Int!
    $checksum: String!
  ) {
    finishActivity(
      request: {
        finalSecondsElapsed: $finalSecondsElapsed
        finalWordsAdded: $finalWordsAdded
        finalWordsDeleted: $finalWordsDeleted
        checksum: $checksum
      }
    ) {
      checksum
    }
  }
`;

const PUBLISH_ACTIVITY = gql`
  mutation PublishActivity(
    $title: String!
    $description: String
    $checksum: String!
  ) {
    publishActivity(
      request: { title: $title, description: $description, checksum: $checksum }
    ) {
      checksum
    }
  }
`;

const DELETE_ACTIVITY = gql`
  mutation DeleteActivity($activityID: String!) {
    deleteActivity(request: { activityID: $activityID }) {
      success
    }
  }
`;

export function useCurrentActivity() {
  const { draftID } = useParams();
  const dispatch = useDispatch();

  const loaded = useSelector((state: RootState) => state.activity.loaded);
  const wordsAdded = useSelector(
    (state: RootState) => state.activity.wordsAdded
  );
  const wordsDeleted = useSelector(
    (state: RootState) => state.activity.wordsDeleted
  );
  const secondsElapsed = useSelector(
    (state: RootState) => state.activity.secondsElapsed
  );
  const status = useSelector((state: RootState) => state.activity.status);
  const secondsElapsedArray = useSelector(
    (state: RootState) => state.activity.secondsElapsedArray
  );
  const wordDifferentialArray = useSelector(
    (state: RootState) => state.activity.wordDifferentialArray
  );
  const activityID = useSelector(
    (state: RootState) => state.activity.activityID
  );
  const title = useSelector((state: RootState) => state.activity.title);
  const description = useSelector(
    (state: RootState) => state.activity.description
  );
  const checksum = useSelector((state: RootState) => state.activity.checksum);
  const outOfSync = useSelector((state: RootState) => state.activity.outOfSync);

  // ------- Queries --------
  const [getActivityForUser] = useLazyQuery(GET_ACTIVITY_FOR_USER, {
    fetchPolicy: "no-cache",
  });
  const [getChartData] = useLazyQuery(GET_CHART_DATA, {
    fetchPolicy: "no-cache",
  });

  // ------- Mutations --------
  const [incrementActivityMutation] = useMutation(INCREMENT_ACTIVITY, {
    onCompleted: (data) => {
      dispatch(updateChecksum(data.incrementActivityData.checksum));
    },
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });

  const [pauseActivityMutation] = useMutation(PAUSE_ACTIVITY, {
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });
  const [modifyActivityMutation] = useMutation(MODIFY_FINISHED_ACTIVITY, {
    onCompleted: (data) => {
      dispatch(updateChecksum(data.modifyFinishedActivity.checksum));
    },
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });
  const [unpauseActivityMutation] = useMutation(UNPAUSE_ACTIVITY, {
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });
  const [finishActivityMutation] = useMutation(FINISH_ACTIVITY, {
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });
  const [publishActivityMutation] = useMutation(PUBLISH_ACTIVITY, {
    onError: (e) => {
      const errorCodes = e.graphQLErrors.map((error) => error.extensions?.code);
      if (errorCodes.includes("CHECKSUM_MISMATCH")) {
        dispatch(setOutOfSync());
      }
    },
  });
  const [deleteActivityMutation] = useMutation(DELETE_ACTIVITY);

  // ------- Callbacks --------

  const maybeIncrementActivity = useCallback(
    async ({
      wordsAddedDelta,
      wordsDeletedDelta,
    }: {
      wordsAddedDelta?: number;
      wordsDeletedDelta?: number;
    }) => {
      if (status === ActivityStatus.Active) {
        await incrementActivityMutation({
          variables: {
            activityID,
            secondsElapsed,
            wordsAdded: wordsAddedDelta
              ? wordsAdded + wordsAddedDelta
              : wordsAdded,
            wordsDeleted: wordsDeletedDelta
              ? wordsDeleted + wordsDeletedDelta
              : wordsDeleted,
            checksum,
          },
        });
      }
    },
    [
      activityID,
      checksum,
      incrementActivityMutation,
      secondsElapsed,
      status,
      wordsAdded,
      wordsDeleted,
    ]
  );

  const debouncedIncrementActivity = useDebouncedCallback(
    maybeIncrementActivity,
    1000
  );
  const debouncedModifyFinishedActivityMutation = useDebouncedCallback(
    modifyActivityMutation,
    1000
  );

  const incrementActivity = useCallback(
    async ({
      wordsAddedDelta,
      wordsDeletedDelta,
    }: {
      wordsAddedDelta?: number;
      wordsDeletedDelta?: number;
    }) => {
      await debouncedIncrementActivity({
        wordsAddedDelta,
        wordsDeletedDelta,
      });
      if (wordsAddedDelta) {
        dispatch(incrementWordsAdded(wordsAddedDelta));
      }
      if (wordsDeletedDelta) {
        dispatch(incrementWordsDeleted(wordsDeletedDelta));
      }
    },
    [debouncedIncrementActivity, dispatch]
  );
  const modifyFinishedActivity = useCallback(
    async ({
      title,
      description,
    }: {
      title?: string;
      description?: string;
    }) => {
      dispatch(setTitleAndDescription({ title, description }));
      const response = await debouncedModifyFinishedActivityMutation({
        variables: {
          activityID,
          title,
          description,
        },
      });
      if (response) {
        dispatch(updateChecksum(response.data.modifyFinishedActivity.checksum));
      }
    },
    [activityID, debouncedModifyFinishedActivityMutation, dispatch]
  );

  const pauseActivity = useCallback(async () => {
    if (status === null) return;
    dispatch(pauseRecording());
    const { data } = await pauseActivityMutation({
      variables: {
        activityID: activityID,
        secondsElapsed,
        wordsAdded,
        wordsDeleted,
        checksum,
      },
    });
    if (data) {
      dispatch(updateChecksum(data.pauseActivity.checksum));
    }
  }, [
    status,
    dispatch,
    pauseActivityMutation,
    activityID,
    secondsElapsed,
    wordsAdded,
    wordsDeleted,
    checksum,
  ]);

  const unpauseActivity = useCallback(async () => {
    if (status === null || status !== ActivityStatus.Paused) return;
    dispatch(startRecording());
    const { data } = await unpauseActivityMutation({
      variables: { activityID: activityID, checksum },
    });
    if (data) {
      dispatch(updateChecksum(data.unpauseActivity.checksum));
    }
  }, [status, dispatch, unpauseActivityMutation, activityID, checksum]);

  const finishActivity = useCallback(async () => {
    if (status === null || activityID === undefined) return;
    const finishActivityVariables: FinishActivityInput = {
      finalSecondsElapsed: secondsElapsed,
      finalWordsAdded: wordsAdded,
      finalWordsDeleted: wordsDeleted,
      checksum: checksum,
    };
    dispatch(finishRecording());
    const { data } = await finishActivityMutation({
      variables: finishActivityVariables,
    });
    if (data) {
      dispatch(updateChecksum(data.finishActivity.checksum));
    }
  }, [
    status,
    activityID,
    secondsElapsed,
    wordsAdded,
    wordsDeleted,
    checksum,
    dispatch,
    finishActivityMutation,
  ]);

  const debouncedUnpauseActivity = useDebouncedCallback(unpauseActivity, 100);

  const publishActivity = useCallback(async () => {
    if (status === null || activityID === undefined) return;
    const response = await publishActivityMutation({
      variables: {
        activityID: activityID,
        title: title,
        description: description,
        checksum: checksum,
      },
    });
    if (response.data) {
      dispatch(resetRecording());
    }
    return response;
  }, [
    activityID,
    checksum,
    description,
    dispatch,
    publishActivityMutation,
    status,
    title,
  ]);

  const deleteActivity = useCallback(async () => {
    if (status === null || activityID === undefined) return;
    await deleteActivityMutation({
      variables: {
        activityID: activityID,
      },
    });
    // Moving this later to try to reduce race conditions
    dispatch(resetRecording());
  }, [activityID, deleteActivityMutation, dispatch, status]);

  // ------- useEffect --------
  // FYI if useCurrentActivity is ever used by multiple components at once
  // it may be worth pulling this out

  useEffect(() => {
    if (!loaded) {
      getActivityForUser({
        variables: { draftID },
        onCompleted: (data) => {
          dispatch(setDataFromLoadedActivity(data.getActivityForUser));
        },
      });
    }
  }, [dispatch, draftID, getActivityForUser, loaded]);
  useEffect(() => {
    dispatch(setUnloaded());
  }, [dispatch, draftID]);

  // load chart data
  useEffect(() => {
    if (
      activityID &&
      status === ActivityStatus.Unpublished &&
      !secondsElapsedArray
    ) {
      getChartData({
        variables: { activityID },
        onCompleted: (data) => {
          dispatch(setChartData(data.getActivityLineData));
        },
      });
    }
  }, [
    activityID,
    dispatch,
    draftID,
    getChartData,
    secondsElapsedArray,
    status,
  ]);

  return {
    wordsAdded,
    wordsDeleted,
    secondsElapsed,
    status,
    activityID,
    loaded,
    incrementActivity,
    secondsElapsedArray,
    wordDifferentialArray,
    modifyFinishedActivity,
    title,
    description,
    checksum,
    outOfSync,
    pauseActivity,
    unpauseActivity,
    finishActivity,
    publishActivity,
    deleteActivity,
    debouncedUnpauseActivity,
    debouncedModifyFinishedActivityMutation,
  };
}
