import { useMemo } from "react";
import { FollowingStatus } from "../gql/graphql";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { gql, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";
import { useMediaQuery } from "react-responsive";

const FOLLOW_USER = gql`
  mutation FollowUser($username: String!) {
    followUser(request: { username: $username }) {
      success
    }
  }
`;

const UNFOLLOW_USER = gql`
  mutation UnfollowUser($username: String!) {
    unfollowUser(request: { username: $username }) {
      success
    }
  }
`;

const ACCEPT_FOLLOW_REQUEST = gql`
  mutation AcceptFollowRequest($username: String!) {
    acceptUserFollow(request: { username: $username }) {
      success
    }
  }
`;

const DECLINE_FOLLOW_REQUEST = gql`
  mutation DeclineFollowRequest($username: String!) {
    declineUserFollow(request: { username: $username }) {
      success
    }
  }
`;

const REMOVE_FOLLOWER = gql`
  mutation RemoveFollower($username: String!) {
    removeUserFollow(request: { username: $username }) {
      success
    }
  }
`;

function RequestButton({
  setOtherUserInfoState,
  otherUserInfoState,
}: {
  setOtherUserInfoState: (args: {
    followingStatus: FollowingStatus | null;
    followerStatus: FollowingStatus | null;
    private: boolean;
    followerCount: number;
    followingCount: number;
    followers: string[];
    following: string[];
    percentWordsThisWeek: number;
    percentWordsAllTime: number;
    sundialScore: number;
  }) => void;
  otherUserInfoState: {
    followingStatus: FollowingStatus | null;
    followerStatus: FollowingStatus | null;
    private: boolean;
    followerCount: number;
    followingCount: number;
    followers: string[];
    following: string[];
    percentWordsThisWeek: number;
    percentWordsAllTime: number;
    sundialScore: number;
  };
}) {
  const { username } = useParams();
  const isSmallMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });
  const navigate = useNavigate();
  const { userID } = useUserInfo();
  const [followUser] = useMutation(FOLLOW_USER);
  const [unfollowUser] = useMutation(UNFOLLOW_USER);
  const [acceptFollowRequest] = useMutation(ACCEPT_FOLLOW_REQUEST);
  const [declineFollowRequest] = useMutation(DECLINE_FOLLOW_REQUEST);
  const [removeFollower] = useMutation(REMOVE_FOLLOWER);
  const followingButtonText = useMemo(() => {
    if (otherUserInfoState?.followingStatus === FollowingStatus.Accepted) {
      return "Unfollow";
    } else if (
      otherUserInfoState?.followingStatus === FollowingStatus.Pending
    ) {
      return "Requested";
    } else {
      return "Follow";
    }
  }, [otherUserInfoState?.followingStatus]);
  return (
    <div style={{ width: "100%" }}>
      {!otherUserInfoState.followingStatus && (
        <Button
          className="w-full font-sans"
          style={{
            height: 30,
            minWidth: isSmallMobile ? 0 : undefined,
            width: isSmallMobile ? "100%" : undefined,
            paddingLeft: isSmallMobile ? 0 : undefined,
            paddingRight: isSmallMobile ? 0 : undefined,
          }}
          onClick={async () => {
            if (!userID) {
              navigate("/login");
              return;
            }
            await followUser({ variables: { username } });
            if (otherUserInfoState.private) {
              setOtherUserInfoState({
                ...otherUserInfoState,
                followingStatus: FollowingStatus.Pending,
              });
            } else {
              setOtherUserInfoState({
                ...otherUserInfoState,
                followingStatus: FollowingStatus.Accepted,
              });
            }
          }}
        >
          {followingButtonText}
        </Button>
      )}
      {otherUserInfoState.followingStatus && (
        <Dropdown
          className="font-sans w-full"
          style={{ width: isSmallMobile ? "100%" : 256 }}
        >
          <DropdownTrigger>
            <Button
              className="w-full font-sans"
              style={{
                height: 30,
                minWidth: isSmallMobile ? 0 : undefined,
                width: isSmallMobile ? "100%" : undefined,
                paddingLeft: isSmallMobile ? 0 : undefined,
                paddingRight: isSmallMobile ? 0 : undefined,
              }}
            >
              {followingButtonText}
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-labelledby="dropdown-menu" className="w-full">
            <DropdownItem
              className="w-full"
              key="unfollow"
              onClick={async () => {
                await unfollowUser({ variables: { username } });
                setOtherUserInfoState({
                  ...otherUserInfoState,
                  followingStatus: null,
                });
              }}
            >
              {otherUserInfoState.followingStatus === FollowingStatus.Accepted
                ? "Unfollow"
                : "Cancel request"}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      {otherUserInfoState.followerStatus && (
        <div className="mt-2">
          <Dropdown
            className="font-sans w-full"
            style={{ width: isSmallMobile ? "100%" : 256 }}
          >
            <DropdownTrigger>
              <Button
                className="w-full font-sans"
                style={{
                  height: 30,
                  minWidth: isSmallMobile ? 0 : undefined,
                  width: isSmallMobile ? "100%" : undefined,
                  paddingLeft: isSmallMobile ? 0 : undefined,
                  paddingRight: isSmallMobile ? 0 : undefined,
                }}
              >
                {otherUserInfoState.followerStatus === FollowingStatus.Pending
                  ? "Respond to request"
                  : "Following you"}
              </Button>
            </DropdownTrigger>
            {otherUserInfoState.followerStatus === FollowingStatus.Pending && (
              <DropdownMenu aria-labelledby="dropdown-menu" className="w-full">
                <DropdownItem
                  className="w-full"
                  key="accept"
                  onClick={async () => {
                    await acceptFollowRequest({ variables: { username } });
                    setOtherUserInfoState({
                      ...otherUserInfoState,
                      followerStatus: FollowingStatus.Accepted,
                    });
                  }}
                >
                  Accept
                </DropdownItem>
                <DropdownItem
                  className="w-full"
                  key="decline"
                  onClick={async () => {
                    await declineFollowRequest({ variables: { username } });
                    setOtherUserInfoState({
                      ...otherUserInfoState,
                      followerStatus: null,
                    });
                  }}
                >
                  Decline
                </DropdownItem>
              </DropdownMenu>
            )}
            {otherUserInfoState.followerStatus === FollowingStatus.Accepted && (
              <DropdownMenu aria-labelledby="dropdown-menu" className="w-full">
                <DropdownItem
                  className="w-full"
                  key="remove-follower"
                  onClick={async () => {
                    await removeFollower({ variables: { username } });
                    setOtherUserInfoState({
                      ...otherUserInfoState,
                      followerStatus: null,
                    });
                  }}
                >
                  Remove follower
                </DropdownItem>
              </DropdownMenu>
            )}
          </Dropdown>
        </div>
      )}
    </div>
  );
}

export default RequestButton;
