import { useCallback, useState } from "react";
import "../App.css";
import * as opaque from "@serenity-kit/opaque";
import { Button, Input, Tooltip } from "@nextui-org/react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import {
  BeginRegistrationRequest,
  BeginRegistrationResponse,
  FinishLoginRequest,
  FinishRegistrationRequest,
  StartLoginRequest,
  StartLoginResponse,
} from "../gql/graphql";
import { Link, useNavigate } from "react-router-dom";
import nacl from "tweetnacl";
import {
  deriveKeyFromMnemonic,
  encryptSymmetric,
  generateMnemonic,
  hashString,
  toByteArray,
  fromByteArrayB64,
  clearLocalStorage,
  setExportKey,
} from "../crypto/utils";
import { Eye, EyeSlash } from "iconsax-react";
import Logo from "../assets/logo.png";

const START_LOGIN_QUERY = gql`
  query StartLogin($email: String!, $startLoginRequest: String!) {
    startLogin(
      request: { email: $email, startLoginRequest: $startLoginRequest }
    ) {
      loginResponse
      userID
    }
  }
`;

const FINISH_LOGIN_QUERY = gql`
  query FinishLogin($finishLoginRequest: String!, $userID: String!) {
    finishLogin(
      request: { finishLoginRequest: $finishLoginRequest, userID: $userID }
    ) {
      success
    }
  }
`;

const CONFIRM_EMAIL_MUTATION = gql`
  mutation ConfirmEmail($email: String!) {
    confirmEmail(request: { email: $email }) {
      success
    }
  }
`;

const BEGIN_REGISTRATION_QUERY = gql`
  query BeginRegistration(
    $registrationRequest: String!
    $username: String!
    $email: String!
    $emailConfirmationCode: String!
  ) {
    beginRegistration(
      request: {
        registrationRequest: $registrationRequest
        username: $username
        email: $email
        emailConfirmationCode: $emailConfirmationCode
      }
    ) {
      registrationResponse
      userID
      username
    }
  }
`;

const FINISH_REGISTRATION_MUTATION = gql`
  mutation FinishRegistration(
    $displayEmail: String!
    $registrationRecord: String!
    $userID: String!
    $username: String!
    $encryptedPrivateKey: String!
    $encryptedExportKey: String!
    $decryptedExportKey: String
    $hashedExportKey: String!
  ) {
    finishRegistration(
      request: {
        displayEmail: $displayEmail
        registrationRecord: $registrationRecord
        userID: $userID
        username: $username
        encryptedPrivateKey: $encryptedPrivateKey
        encryptedExportKey: $encryptedExportKey
        decryptedExportKey: $decryptedExportKey
        hashedExportKey: $hashedExportKey
      }
    ) {
      success
    }
  }
`;

enum LoginStep {
  EmailEntry,
  UsernameEntry,
  ShowRecoveryKey,
}

function CreateAccount() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveDecryptedExportKey, setSaveDecryptedExportKey] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loginStep, setLoginStep] = useState<LoginStep>(LoginStep.EmailEntry);
  const [recoveryKeyCopied, setRecoveryKeyCopied] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [emailConfirmationCode, setEmailConfirmationCode] = useState("");
  const [beginRegistrationQuery] = useLazyQuery(BEGIN_REGISTRATION_QUERY, {
    variables: { email, username },
  });
  const [finishRegistrationMutation] = useMutation(
    FINISH_REGISTRATION_MUTATION
  );
  const [startLoginQuery] = useLazyQuery(START_LOGIN_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [finishLoginQuery] = useLazyQuery(FINISH_LOGIN_QUERY, {
    fetchPolicy: "no-cache",
  });
  const [confirmEmailMutation, { loading: confirmEmailLoading }] = useMutation(
    CONFIRM_EMAIL_MUTATION
  );
  const [recoveryKey, setRecoveryKey] = useState("");
  const navigate = useNavigate();

  const registerUser = useCallback(async () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      const { clientRegistrationState, registrationRequest } =
        opaque.client.startRegistration({ password });
      const beginRegistrationVariables: BeginRegistrationRequest = {
        username,
        registrationRequest,
        email,
        emailConfirmationCode,
      };
      const registrationResponse: {
        data: { beginRegistration: BeginRegistrationResponse };
      } = await beginRegistrationQuery({
        variables: beginRegistrationVariables,
      });
      const { registrationRecord, exportKey } =
        opaque.client.finishRegistration({
          clientRegistrationState,
          password,
          registrationResponse:
            registrationResponse.data.beginRegistration.registrationResponse,
        });
      const decryptionKey = nacl.randomBytes(1024);
      const mnemonic = generateMnemonic();
      setRecoveryKey(mnemonic);
      const mnemonicKey = deriveKeyFromMnemonic({ mnemonic });
      const encryptedDecryptionKey = encryptSymmetric({
        secretKey: exportKey,
        decryptedPayload: decryptionKey,
      });
      const encryptedExportKey = encryptSymmetric({
        secretKey: fromByteArrayB64(mnemonicKey),
        decryptedPayload: toByteArray(exportKey),
      });
      const hashedExportKey = hashString(exportKey);
      const finishRegistrationVariables: FinishRegistrationRequest = {
        displayEmail: email,
        registrationRecord: registrationRecord,
        userID: registrationResponse.data.beginRegistration.userID,
        username,
        encryptedPrivateKey: encryptedDecryptionKey,
        encryptedExportKey: encryptedExportKey,
        decryptedExportKey: saveDecryptedExportKey ? exportKey : null,
        hashedExportKey: hashedExportKey,
      };
      await finishRegistrationMutation({
        variables: finishRegistrationVariables,
      });
    } catch (e) {
      console.error(e);
      alert("Registration failed");
      setLoading(false);
    }
    setLoading(false);
    const { clientLoginState, startLoginRequest } = opaque.client.startLogin({
      password: password,
    });
    const startLoginVariables: StartLoginRequest = {
      email: email,
      startLoginRequest: startLoginRequest,
    };
    const result: { data: { startLogin: StartLoginResponse } } =
      await startLoginQuery({
        variables: startLoginVariables,
      });
    const loginResult = opaque.client.finishLogin({
      clientLoginState,
      loginResponse: result.data.startLogin.loginResponse,
      password,
    });

    if (!loginResult) {
      clearLocalStorage();
      throw new Error("Login failed");
    }

    setExportKey(loginResult.exportKey);

    const finishLoginVariables: FinishLoginRequest = {
      finishLoginRequest: loginResult.finishLoginRequest,
      userID: result.data.startLogin.userID,
    };
    await finishLoginQuery({
      variables: finishLoginVariables,
    });
    setLoginStep(LoginStep.ShowRecoveryKey);
  }, [
    password,
    confirmPassword,
    email,
    startLoginQuery,
    finishLoginQuery,
    username,
    emailConfirmationCode,
    beginRegistrationQuery,
    saveDecryptedExportKey,
    finishRegistrationMutation,
  ]);
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <Link to="/">
        <div
          className="absolute top-0 left-0 flex flex-row items-center cursor-pointer"
          style={{ marginLeft: 12, marginTop: 8 }}
        >
          <img src={Logo} alt="logo" width={40} />
          <p
            className="ml-2 font-semibold"
            style={{ fontFamily: "Nothing You Could Do", fontSize: 20 }}
          >
            draft zero
          </p>
        </div>
      </Link>
      <div style={{ width: 300 }}>
        <p className="font-sans">
          {loginStep === LoginStep.ShowRecoveryKey
            ? "Recovery key"
            : "Create an account"}
        </p>
        {loginStep === LoginStep.EmailEntry && (
          <div style={{ marginBottom: 10, marginTop: 10 }}>
            <Input
              value={email}
              className="font-sans"
              onValueChange={setEmail}
              label="email"
            />
          </div>
        )}
        {loginStep === LoginStep.UsernameEntry && (
          <>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <Input
                value={username}
                className="font-sans"
                onValueChange={setUsername}
                label="username"
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <Input
                type={passwordVisible ? "text" : "password"}
                value={password}
                className="font-sans"
                onValueChange={setPassword}
                label="password"
                endContent={
                  <Button
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                    variant="light"
                    style={{ minWidth: 0, marginRight: -5 }}
                    disableRipple={true}
                  >
                    {passwordVisible ? (
                      <EyeSlash size={24} />
                    ) : (
                      <Eye size={24} />
                    )}
                  </Button>
                }
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <Input
                label="confirm password (no pasting)"
                type={confirmPasswordVisible ? "text" : "password"}
                value={confirmPassword}
                onValueChange={setConfirmPassword}
                className="font-sans"
                onPaste={(e) => e.preventDefault()}
                endContent={
                  <Button
                    onClick={() => {
                      setConfirmPasswordVisible(!confirmPasswordVisible);
                    }}
                    variant="light"
                    style={{ minWidth: 0, marginRight: -5 }}
                    disableRipple={true}
                  >
                    {confirmPasswordVisible ? (
                      <EyeSlash size={24} />
                    ) : (
                      <Eye size={24} />
                    )}
                  </Button>
                }
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <Input
                label="verification code (in your email)"
                value={emailConfirmationCode}
                onValueChange={setEmailConfirmationCode}
                className="font-sans"
              />
            </div>
          </>
        )}
        {loginStep === LoginStep.ShowRecoveryKey && (
          <>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <p className="font-sans">
                Save this key somewhere safe. If you forget your password, you
                will not be able to recover your account without it. This is the
                only time you will see this key.
              </p>
            </div>
            <div
              style={{
                marginBottom: 10,
                backgroundColor: "#d4d4d8",
                borderRadius: 10,
                padding: 10,
              }}
            >
              <p className="font-sans">{recoveryKey}</p>
            </div>
          </>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button className="font-sans">
            <Link to="/login">Login instead</Link>
          </Button>
          {loginStep === LoginStep.EmailEntry && (
            <Button
              color="primary"
              onClick={async () => {
                await confirmEmailMutation({
                  variables: { email },
                });
                setLoginStep(LoginStep.UsernameEntry);
              }}
              className="font-sans"
              isLoading={confirmEmailLoading}
            >
              Verify email
            </Button>
          )}
          {loginStep === LoginStep.UsernameEntry && (
            <Button
              color="primary"
              onClick={async () => await registerUser()}
              className="font-sans"
              isLoading={loading}
            >
              Register
            </Button>
          )}
          {loginStep === LoginStep.ShowRecoveryKey && (
            <div className="relative">
              <Button
                color="primary"
                onClick={() => {
                  if (recoveryKeyCopied) {
                    navigate("/welcome");
                    return;
                  }
                  navigator.clipboard.writeText(recoveryKey);
                  setRecoveryKeyCopied(true);
                }}
                className="font-sans"
              >
                {recoveryKeyCopied ? "Continue" : "Copy recovery key"}
              </Button>
              {recoveryKeyCopied && (
                <div
                  className="absolute"
                  style={{
                    backgroundColor: "black",
                    top: -50,
                    right: 7.5,
                    borderRadius: 10,
                  }}
                >
                  <p
                    className="font-sans"
                    style={{ color: "white", padding: 10 }}
                  >
                    Copied!
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// peace mandate blood jazz useful describe seek laptop fox aware record double bitter vague coach ring bomb anger subject icon various awful table aim

export default CreateAccount;
