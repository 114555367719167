import { Button } from "@nextui-org/react";
import { ArrowRight } from "iconsax-react";
import Logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function Welcome() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });
  return (
    <div
      className="flex flex-col items-center justify-center font-sans h-full"
      style={{ padding: 30 }}
    >
      <div
        className="flex flex-col"
        style={{ width: isMobile ? "100%" : 700, padding: 20 }}
      >
        <div
          className="flex flex-row items-center justify-center"
          style={{ marginLeft: 12, marginTop: 8, marginBottom: 40 }}
        >
          <img src={Logo} alt="logo" width={60} />
          <p
            className="font-semibold"
            style={{
              fontFamily: "Nothing You Could Do",
              fontSize: 30,
              marginLeft: 12,
            }}
          >
            draft zero
          </p>
        </div>
        <div style={{ marginBottom: 20 }}>
          <p>
            {`Draft Zero is a celebration of the writing process.
            Here, you can share your writing sessions and support those of your friends.
            We're here for the long sessions where you get writers block and can't think of anything,
            or the times you spend an hour just pouring over the wording of a single paragraph,
            or those moments you are writing just to organize your thoughts. Writing is always hard, but with the
            support of friends and your community, it can be just a little bit easier.`}
          </p>
        </div>
        <div style={{ marginBottom: 20 }}>
          <p>{`We believe that writing doesn't need to be shared to be valuable.
          There is no mechanism to publish your writing on Draft Zero, and your data is encrypted by a key that never leaves your device.
          This means that no one but you can read your writing.`}</p>
        </div>
        <div style={{ marginBottom: 50 }}>
          <p>{`We hope that you enjoy your time here and
          can bring a little more writing into your life.`}</p>
        </div>
      </div>
      <Button
        color="primary"
        endContent={<ArrowRight />}
        onClick={() => {
          navigate("/");
        }}
      >
        ENTER
      </Button>
    </div>
  );
}

export default Welcome;
