import {
  Avatar,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import Logo from "../assets/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { setExportKey } from "../crypto/utils";
import { useUserInfo } from "../hooks/useUserInfo";
import { clearUserInfo } from "../redux/userStore";
import { useDispatch } from "react-redux";

const LOGOUT_QUERY = gql`
  query Logout {
    logout
  }
`;

enum DropdownKeys {
  Logout = "Logout",
  Profile = "Profile",
  Settings = "Settings",
}

function Header({ children }: { children?: React.ReactNode }) {
  const location = useLocation();
  const { username, profilePhotoDownloadURL } = useUserInfo();
  const isHome = useMemo(() => location.pathname === "/", [location.pathname]);
  const [logoutQuery] = useLazyQuery(LOGOUT_QUERY, { fetchPolicy: "no-cache" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = useCallback(async () => {
    await logoutQuery();
    dispatch(clearUserInfo());
    setExportKey("");
    navigate("/login");
  }, [dispatch, logoutQuery, navigate]);
  return (
    <>
      <div
        className="w-full flex flex-row py-2 items-center border-b border-solid border-black px-3 fixed"
        style={{ backgroundColor: "white", zIndex: 1000 }}
      >
        {isHome ? (
          <Image width={40} src={Logo} />
        ) : (
          <Link to="/">
            <Image width={40} src={Logo} />
          </Link>
        )}
        <div className="flex-1">{children}</div>
        <Dropdown className="font-sans">
          <DropdownTrigger>
            <Avatar
              className="cursor-pointer"
              src={profilePhotoDownloadURL ?? ""}
              showFallback
            />
          </DropdownTrigger>
          <DropdownMenu
            aria-labelledby="profile-menu"
            topContent={
              <div
                style={{
                  color: "#7194A8",
                  paddingLeft: 8,
                  borderBottomWidth: 1,
                  borderBottomColor: "#d4d4d8",
                  paddingBottom: 4,
                }}
                className="font-sans"
              >
                <p>{username}</p>
              </div>
            }
            onAction={(key) => {
              if (key === DropdownKeys.Logout) {
                logout();
              } else if (key === DropdownKeys.Profile) {
                navigate(`/writer/${username}`);
              } else if (key === DropdownKeys.Settings) {
                navigate("/settings");
              }
            }}
          >
            <DropdownItem
              key={DropdownKeys.Profile}
            >
              Profile
            </DropdownItem>
            <DropdownItem
              key={DropdownKeys.Settings}
            >
              Settings
            </DropdownItem>
            <DropdownItem
              key={DropdownKeys.Logout}
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div style={{ minHeight: 57 }}></div>
    </>
  );
}

export default Header;
