import { gql, useQuery } from "@apollo/client";
import { Button, Input } from "@nextui-org/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Socket } from "socket.io-client";
import { useUserInfo } from "../hooks/useUserInfo";
import { ArrowRight2 } from "iconsax-react"; // Added this import

type Message = {
  content: string;
  messageID: string;
  createdAt: string;
  username: string;
};

const GET_MESSAGES_FOR_ROOM = gql`
  query GetMessagesForRoom($roomID: String!) {
    getMessagesForRoom(request: { roomID: $roomID }) {
      content
      messageID
      createdAt
      username
    }
  }
`;

export function ChatBox({ socket }: { socket: Socket | null }) {
  const { roomID } = useParams();
  const [isChatOpen, setIsChatOpen] = useState(false);

  const [messages, setMessages] = useState<Message[]>([]);
  const { userID } = useUserInfo();

  // initial fetch of messages
  useQuery(GET_MESSAGES_FOR_ROOM, {
    variables: { roomID },
    onCompleted: (data) => {
      setMessages(data.getMessagesForRoom);
    },
  });
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const [messageInput, setMessageInput] = useState("");
  const scrollWindowRef = useRef<HTMLDivElement>(null);
  const sendMessage = useCallback(() => {
    if (socket) {
      socket.emit("send_chat_message", {
        message: messageInput,
        roomID,
        userID,
      });
      setMessageInput("");
    }
  }, [messageInput, roomID, socket, userID]);

  const scrollToBottom = useCallback(() => {
    if (scrollWindowRef.current) {
      scrollWindowRef.current.scrollTop = scrollWindowRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("new_message", (...args) => {
        setMessages((prevMessages) => [...prevMessages, args[0]]);
        setTimeout(scrollToBottom, 10);
      });
    }
  }, [socket, scrollToBottom]);

  useEffect(() => {
    if (isChatOpen) {
      scrollToBottom();
    }
  }, [isChatOpen, scrollToBottom]);

  return (
    <div
      className="h-full flex transition-all duration-300 ease-in-out overflow-hidden"
      style={{
        width: isChatOpen ? "500px" : "30px",
      }}
    >
      <div className="flex flex-col justify-end">
        <div
          className={`bg-white cursor-pointer flex items-center justify-center ${
            isChatOpen ? "border-t border-l border-r" : ""
          } border-gray-400 rounded-t-md px-2`}
          style={{
            width: 20,
            height: 100,
            writingMode: "vertical-rl",
            textOrientation: "mixed",
            borderColor: "#e0e0e0",
            borderWidth: "1px",
            borderRightWidth: isChatOpen ? "0px" : "1px",
          }}
          onClick={() => setIsChatOpen(!isChatOpen)}
        >
          <div style={{ marginBottom: 10 }}>
            <p
              className="transform rotate-180 font-sans"
              style={{ fontSize: 12 }}
            >
              Chat
            </p>
          </div>
          <ArrowRight2
            size="16"
            className={`mt-4 transition-transform duration-300 ${
              isChatOpen ? "" : "rotate-180"
            }`}
          />
        </div>
      </div>
      <div
        className={`flex-1 bg-white flex flex-col ${
          isChatOpen ? "border border-gray-400" : ""
        } transition-all duration-300 ease-in-out ${
          isChatOpen ? "w-[280px]" : "w-0"
        }`}
        style={{
          margin: 20,
          marginBottom: 0,
          marginLeft: isChatOpen ? 0 : 20,
          marginRight: 10,
        }}
      >
        <div
          className="flex-1"
          ref={scrollWindowRef}
          style={{
            maxHeight: 300,
            minHeight: 50,
            overflowY: "auto",
          }}
        >
          <div
            className="flex-1 flex flex-col justify-end"
            style={{ padding: 20 }}
          >
            {messages.map((message) => (
              <div
                className="flex flex-row items-start"
                key={`message_${message.messageID}`}
              >
                <div
                  className="flex flex-col items-center"
                  style={{ marginRight: 10 }}
                >
                  <div className="font-sans">
                    <p style={{ fontSize: 12, fontWeight: "bold" }}>
                      {message.username}
                    </p>
                  </div>
                </div>
                <div
                  className="flex flex-col"
                  style={{
                    width: "100%",
                  }}
                >
                  <p
                    className="font-sans"
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {message.content}
                  </p>
                </div>
                <div className="flex flex-row justify-end">
                  <p
                    className="font-mono"
                    style={{
                      lineHeight: 2,
                      fontSize: 12,
                      width: 80,
                      textAlign: "right",
                    }}
                  >
                    {new Date(message.createdAt).toLocaleTimeString()}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div ref={scrollViewRef} />
        </div>
        <div
          className="flex"
          style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
        >
          <Input
            placeholder="Chat here"
            classNames={{
              inputWrapper: ["h-[24px] font-sans"],
            }}
            variant="underlined"
            size="sm"
            onValueChange={(value) => {
              setMessageInput(value);
            }}
            value={messageInput}
            onKeyDown={(e) => {
              if (e.key === "Enter" && messageInput.trim()) {
                sendMessage();
              }
            }}
          />

          <div style={{ marginLeft: 16 }}>
            <Button
              size="sm"
              className="font-sans"
              onClick={sendMessage}
              isDisabled={!messageInput.trim()}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
