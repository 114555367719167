import { motion } from "framer-motion";

const SCORE_INTERVALS = [
  -1,
  -0.75, // daybreak, 4am-7am
  -0.42, // morning, 7am-11am
  -0.25, // midday, 11am-1pm
  0.09, // afternoon, 1pm-5pm
  0.5, // evening, 5pm-10pm
  0.75, // night, 10pm-1am
  1, // dead of night, 1am-4am
];

function getSundialName(score: number) {
  if (score < SCORE_INTERVALS[1]) {
    return "daybreak";
  } else if (score < SCORE_INTERVALS[2]) {
    return "morning";
  } else if (score < SCORE_INTERVALS[3]) {
    return "midday";
  } else if (score < SCORE_INTERVALS[4]) {
    return "afternoon";
  } else if (score < SCORE_INTERVALS[5]) {
    return "evening";
  } else if (score < SCORE_INTERVALS[6]) {
    return "night";
  } else {
    return "dead of night";
  }
}

export default function Sundial({ sundialScore }: { sundialScore: number }) {
  const angleOfRotation = sundialScore * 90;
  return (
    <div className="flex flex-col items-center">
      <div
        style={{
          marginTop: 4.5,
          marginBottom: 4.5,
          width: 78,
          height: 39,
          position: "relative",
          background:
            "conic-gradient(from 270deg at 50% 100%, #E5C852, #25536D 180deg)",
          borderTopLeftRadius: 39,
          borderTopRightRadius: 39,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: angleOfRotation }}
          style={{
            position: "absolute",
            bottom: 1,
            right: 38,
            width: 2,
            height: 30,
            backgroundColor: "black",
            transformOrigin: "bottom center",
            borderRadius: 2,
          }}
        />
      </div>
      <div className="flex flex-col items-center">
        <p className="font-sans" style={{ fontSize: 12 }}>
          {getSundialName(sundialScore)}
        </p>
        <p className="font-sans" style={{ fontSize: 12 }}>
          writer
        </p>
      </div>
    </div>
  );
}
