import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface DraftState {
  title: string | null;
  titleChecksum: string | null;
  draftHtml: string | null;
  draftChecksum: string | null;
  noteHtml: string | null;
  noteChecksum: string | null;
  loaded: boolean;
  outOfSync: boolean;
  noteDraftSplit: number | null;
  draftPlaylist: string | null;
  playlistName: string | null;
}

const initialDraftState: DraftState = {
  title: null,
  draftHtml: null,
  noteHtml: null,
  titleChecksum: null,
  draftChecksum: null,
  noteChecksum: null,
  loaded: false,
  outOfSync: false,
  noteDraftSplit: null,
  draftPlaylist: null,
  playlistName: null,
};

const draftSlice = createSlice({
  name: "draft",
  initialState: initialDraftState,
  reducers: {
    setDraftTitle: (
      state,
      action: PayloadAction<{
        title: string | null;
        checksum?: string | null;
      } | null>
    ) => {
      if (!action.payload) {
        state.title = null;
        state.titleChecksum = null;
      } else {
        state.title = action.payload?.title;
        if (action.payload?.checksum !== undefined) {
          state.titleChecksum = action.payload?.checksum;
        }
      }
    },
    setTitleChecksum: (state, action: PayloadAction<string | null>) => {
      state.titleChecksum = action.payload;
    },
    unsetDraftTitle: (state) => {
      state.title = null;
      state.titleChecksum = null;
    },
    setDraftHtml: (
      state,
      action: PayloadAction<{
        draftHtml: string | null;
        checksum?: string | null;
      } | null>
    ) => {
      if (!action.payload) {
        state.draftHtml = null;
        state.draftChecksum = null;
      } else {
        state.draftHtml = action.payload?.draftHtml;
        if (action.payload?.checksum !== undefined) {
          state.draftChecksum = action.payload?.checksum;
        }
      }
    },
    unsetDraftHtml: (state) => {
      state.draftHtml = null;
      state.draftChecksum = null;
    },
    setDraftChecksum: (state, action: PayloadAction<string | null>) => {
      state.draftChecksum = action.payload;
    },
    setNoteHtml: (
      state,
      action: PayloadAction<{
        noteHtml: string | null;
        checksum?: string | null;
      } | null>
    ) => {
      if (!action.payload) {
        state.noteHtml = null;
        state.noteChecksum = null;
      } else {
        state.noteHtml = action.payload?.noteHtml;
        if (action.payload?.checksum !== undefined) {
          state.noteChecksum = action.payload?.checksum;
        }
      }
    },
    unsetNoteHtml: (state) => {
      state.noteHtml = null;
      state.noteChecksum = null;
    },
    setNoteChecksum: (state, action: PayloadAction<string | null>) => {
      state.noteChecksum = action.payload;
    },
    setDraftLoaded: (state) => {
      state.loaded = true;
    },
    setDraftNotLoaded: (state) => {
      state.loaded = false;
    },
    setNoteDraftSplit: (state, action: PayloadAction<number>) => {
      state.noteDraftSplit = action.payload;
    },
    setDraftPlaylist: (
      state,
      action: PayloadAction<{
        draftPlaylist: string;
        playlistName: string | null;
      } | null>
    ) => {
      if (!action.payload) {
        state.draftPlaylist = null;
        state.playlistName = null;
      } else {
        state.draftPlaylist = action.payload.draftPlaylist;
        state.playlistName = action.payload.playlistName;
      }
    },
    unsetDraftPlaylist: (state) => {
      state.draftPlaylist = null;
      state.playlistName = null;
    },
    resetDraftState: (state) => {
      state.draftHtml = null;
      state.title = null;
      state.loaded = false;
      state.noteHtml = null;
      state.outOfSync = false;
      state.noteDraftSplit = null;
    },
    setOutOfSync: (state) => {
      state.outOfSync = true;
    },
    setInitialData: (
      state,
      action: PayloadAction<{
        draftHtml: string | null;
        title: string | null;
        noteHtml: string | null;
        noteChecksum: string | null;
        draftChecksum: string | null;
        titleChecksum: string | null;
        noteDraftSplit: number;
        draftPlaylist: string | null;
        playlistName: string | null;
      }>
    ) => {
      state.draftHtml = action.payload.draftHtml;
      state.title = action.payload.title;
      state.loaded = true;
      state.noteHtml = action.payload.noteHtml;
      state.noteChecksum = action.payload.noteChecksum;
      state.draftChecksum = action.payload.draftChecksum;
      state.titleChecksum = action.payload.titleChecksum;
      state.noteDraftSplit = action.payload.noteDraftSplit;
      state.draftPlaylist = action.payload.draftPlaylist;
      state.playlistName = action.payload.playlistName;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDraftTitle,
  unsetDraftTitle,
  setDraftHtml,
  unsetDraftHtml,
  setNoteHtml,
  unsetNoteHtml,
  setDraftLoaded,
  setDraftNotLoaded,
  resetDraftState,
  setDraftChecksum,
  setNoteChecksum,
  setTitleChecksum,
  setOutOfSync,
  setInitialData,
  setNoteDraftSplit,
  setDraftPlaylist,
  unsetDraftPlaylist,
} = draftSlice.actions;

export default draftSlice.reducer;
