function HomepageDivider() {
  return (
    <div className="flex flex-row justify-center">
      <div
        style={{
          borderColor: "#e0e0e0",
          borderWidth: 1,
          width: "80%",
        }}
      />
    </div>
  );
}

export default HomepageDivider;
