import { Label, Line, LineChart, ResponsiveContainer, XAxis } from "recharts";
import { ActivityFeedMetadata } from "../gql/graphql";
import { convertSecondsToString } from "../utils/string";
import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Textarea,
} from "@nextui-org/react";
import { useNavigate, Link } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { Like1, More } from "iconsax-react";
import { useUserInfo } from "../hooks/useUserInfo";
import { PureComponent, useContext, useMemo, useState } from "react";
import {
  FeedActionType,
  FeedContext,
  FeedContextType,
} from "../providers/FeedProvider";
import { v4 } from "uuid";
import ActivityCommentThread from "./ActivityCommentThread";
import { CartesianViewBox, ViewBox } from "recharts/types/util/types";
import { useMediaQuery } from "react-responsive";

const DELETE_ACTIVITY = gql`
  mutation DeleteActivityFromItem($activityID: String!) {
    deleteActivity(request: { activityID: $activityID }) {
      success
    }
  }
`;

const MODIFY_FINISHED_ACTIVITY = gql`
  mutation modifyFinishedActivity(
    $activityID: String!
    $title: String
    $description: String
    $checksum: String!
  ) {
    modifyFinishedActivity(
      request: {
        activityID: $activityID
        title: $title
        description: $description
        checksum: $checksum
      }
    ) {
      checksum
    }
  }
`;

const LIKE_ACTIVITY = gql`
  mutation likeActivity($activityID: String!, $activityLikeID: String!) {
    likeActivity(
      request: { activityID: $activityID, activityLikeID: $activityLikeID }
    ) {
      success
    }
  }
`;

const UNLIKE_ACTIVITY = gql`
  mutation unlikeActivity($activityID: String!) {
    unlikeActivity(request: { activityID: $activityID }) {
      success
    }
  }
`;

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props as {
      x: number;
      y: number;
      stroke: string;
      payload: { value: number };
    };

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={6}
          y={0}
          dy={16}
          textAnchor="end"
          className="font-sans"
          fontSize={12}
          fill="#666"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

function ActivityFeedItem({
  activityMetadata,
}: {
  activityMetadata: ActivityFeedMetadata;
}) {
  const { dispatch } = useContext<FeedContextType>(FeedContext);
  const { username } = useUserInfo();
  const navigate = useNavigate();
  const [deleteActivity] = useMutation(DELETE_ACTIVITY);
  const [updateActivity] = useMutation(MODIFY_FINISHED_ACTIVITY);
  const [likeActivity] = useMutation(LIKE_ACTIVITY);
  const [unlikeActivity] = useMutation(UNLIKE_ACTIVITY);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(activityMetadata.title ?? "");
  const [description, setDescription] = useState(
    activityMetadata.description ?? ""
  );
  const isSelf = useMemo(
    () => username === activityMetadata.username,
    [activityMetadata.username, username]
  );
  const userActivityLikeID = activityMetadata.activityLikes?.find(
    (like) => like.username === username
  )?.activityLikeID;
  const isMobile = useMediaQuery({ query: "(max-width: 1060px)" });

  return (
    <div
      className="flex flex-col w-full h-full"
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: isMobile ? 20 : 0,
        paddingRight: isMobile ? 20 : 0,
      }}
    >
      <div
        className="h-full"
        style={{
          borderWidth: 1,
          borderColor: "#d4d4d8",
          padding: 30,
        }}
      >
        <div className="flex flex-row justify-between w-full">
          <div
            className="flex flex-row cursor-pointer"
            onClick={() => {
              navigate(`/writer/${activityMetadata.username}`);
            }}
          >
            <Avatar
              src={activityMetadata.imageDownloadURL || ""}
              showFallback
            />
            <div className="flex flex-col ml-2">
              <p className="font-sans font-semibold">
                {activityMetadata.username}
              </p>
              <p className="font-sans" style={{ fontSize: 12 }}>
                {new Date(activityMetadata.createdAt).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )}{" "}
                at{" "}
                {new Date(activityMetadata.createdAt).toLocaleTimeString(
                  "en-US",
                  {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  }
                )}
              </p>
            </div>
          </div>
          {isSelf && !isEditing && (
            <div>
              <Dropdown>
                <DropdownTrigger>
                  <Button isIconOnly variant="light" size="sm">
                    <More size={20} />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu aria-label="activity-item-menu">
                  <DropdownItem
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={async () => {
                      dispatch({
                        type: FeedActionType.Remove,
                        payload: activityMetadata.activityID,
                      });
                      await deleteActivity({
                        variables: {
                          activityID: activityMetadata.activityID,
                        },
                      });
                    }}
                    color="danger"
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
          {isSelf && isEditing && (
            <div className="flex flex-row">
              <Button
                size="sm"
                onClick={() => {
                  setTitle(activityMetadata.title);
                  setDescription(activityMetadata.description ?? "");
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                onClick={async () => {
                  if (!title) {
                    alert("Title is required");
                    return;
                  }
                  dispatch({
                    type: FeedActionType.Update,
                    payload: {
                      activityID: activityMetadata.activityID,
                      title,
                      description,
                    },
                  });
                  await updateActivity({
                    variables: {
                      activityID: activityMetadata.activityID,
                      title,
                      description,
                      checksum: activityMetadata.checksum,
                    },
                  });
                  setIsEditing(false);
                }}
                color="primary"
              >
                Save
              </Button>
            </div>
          )}
        </div>
        <div className="mt-2 mb-2" style={{ maxWidth: 400 }}>
          {!isEditing && (
            <Link to={`/session/${activityMetadata.activityID}`}>
              <div>
                <p className="font-sans font-semibold" style={{ fontSize: 20 }}>
                  {activityMetadata.title}
                </p>
              </div>
            </Link>
          )}
          {isEditing && (
            <div>
              <Input
                value={title}
                onValueChange={(value) => setTitle(value)}
                placeholder="Title"
              />
            </div>
          )}
          {!isEditing && activityMetadata.description && (
            <Link to={`/session/${activityMetadata.activityID}`}>
              <div>
                <p className="font-sans">{activityMetadata.description}</p>
              </div>
            </Link>
          )}
          {isEditing && (
            <div>
              <Textarea
                value={description ?? ""}
                onValueChange={(value) => setDescription(value)}
                placeholder="Description"
              />
            </div>
          )}
        </div>
        <Link to={`/session/${activityMetadata.activityID}`}>
          <div className="flex flex-row">
            <div style={{ marginRight: 10, flex: 1 }}>
              <p className="font-sans" style={{ fontSize: 12 }}>
                Additions
              </p>
              <p className="font-sans">{activityMetadata.wordsAdded} words</p>
            </div>
            <div style={{ marginRight: 10, flex: 1 }}>
              <p className="font-sans" style={{ fontSize: 12 }}>
                Deletions
              </p>
              <p className="font-sans">{activityMetadata.wordsDeleted} words</p>
            </div>
            <div style={{ flex: 1 }}>
              <p className="font-sans" style={{ fontSize: 12 }}>
                Time
              </p>
              <p className="font-sans">
                {convertSecondsToString(activityMetadata.secondsElapsed)}
              </p>
            </div>
          </div>

          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              width={400}
              height={200}
              data={activityMetadata.secondsElapsedArray.map(function (x, i) {
                return {
                  time: x / 60,
                  words: activityMetadata.wordDifferentialArray[i],
                };
              })}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              style={{ cursor: "pointer" }}
            >
              <Line
                type="monotone"
                dataKey="words"
                stroke="#8884d8"
                dot={false}
              />
              <XAxis
                dataKey="time"
                min={0}
                type="number"
                tick={<CustomizedAxisTick />}
                label={
                  <Label
                    value="minutes"
                    position="insideRight"
                    offset={0}
                    content={(props: {
                      viewBox?: ViewBox;
                      value?: string | number;
                    }) => {
                      const cartesianViewBox =
                        props.viewBox as CartesianViewBox;
                      return (
                        <text
                          className="font-sans"
                          style={{ fontSize: "12px" }}
                          x={(cartesianViewBox.width as number) - 30}
                          y={(cartesianViewBox.y as number) - 5}
                          fill="gray"
                        >
                          {props.value}
                        </text>
                      );
                    }}
                  />
                }
              />
            </LineChart>
          </ResponsiveContainer>
        </Link>
        <div className="mt-2 flex flex-col items-start">
          <div className="flex flex-row items-center">
            <div
              className="font-sans flex flex-row items-center"
              style={{ marginLeft: -8 }}
            >
              <Button
                startContent={
                  <Like1
                    variant={userActivityLikeID ? "Bold" : "Linear"}
                    color={userActivityLikeID ? "#7194A8" : "black"}
                  />
                }
                isIconOnly
                variant="light"
                onClick={async () => {
                  if (userActivityLikeID) {
                    dispatch({
                      type: FeedActionType.RemoveActivityLike,
                      payload: {
                        activityID: activityMetadata.activityID,
                        activityLikeID: userActivityLikeID,
                      },
                    });
                    await unlikeActivity({
                      variables: {
                        activityID: activityMetadata.activityID,
                        activityLikeID: userActivityLikeID,
                      },
                    });
                    return;
                  } else {
                    const activityLikeID = v4();
                    dispatch({
                      type: FeedActionType.AddActivityLike,
                      payload: {
                        activityID: activityMetadata.activityID,
                        username,
                        activityLikeID,
                      },
                    });
                    await likeActivity({
                      variables: {
                        activityLikeID,
                        activityID: activityMetadata.activityID,
                      },
                    });
                  }
                }}
              />
            </div>
            <p className="font-sans" style={{ fontSize: 12 }}>
              {activityMetadata.activityLikes?.length > 0
                ? `${activityMetadata.activityLikes?.length} like${
                    activityMetadata.activityLikes?.length !== 1 ? "s" : ""
                  }`
                : "Like"}
            </p>
          </div>
        </div>
        <ActivityCommentThread
          activityID={activityMetadata.activityID}
          comments={activityMetadata.comments}
          dispatch={dispatch}
        />
      </div>
    </div>
  );
}

export default ActivityFeedItem;
