export type SettingData = {
    displayName: string
    description: string
    defaultValue: boolean
};

export const USER_SETTINGS: Record<string, SettingData> = {
    // private: {
    //     displayName: "Private Account",
    //     description: "Private accounts must accept follow requests before they can be followed. Private account feeds are not viewable except by followers.",
    //     defaultValue: true
    // },
    autoRecord: {
        displayName: "Automatic Recording",
        description: "When enabled, activity recording will start automatically when you begin typing",
        defaultValue: true
    },
    // autoSave: {
    //     displayName: "Automatic Saving",
    //     description: "When enabled, your activity will be saved to your profile after a day of inactivity",
    //     defaultValue: true
    // },
}

export type SettingName = keyof typeof USER_SETTINGS;
