import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { USER_SETTINGS } from "../lib/userSettings";

const GET_USER_SETTINGS = gql`
  query GetUserSettings {
    getUserSettings {
      autoRecord
      autoSave
    }
  }
`;


export function useUserSettings() {
    const userID = useSelector((state: RootState) => state.user.userID);
    const [getUserSettings, { loading: userSettingsLoading, data: userSettingsData }] = useLazyQuery(GET_USER_SETTINGS, {
        fetchPolicy: "no-cache",
    });
    useEffect(() => {
        if (userID) {
            getUserSettings();
        }
    }, [userID]);

    const userSettingsOrDefaults = useMemo(() => {
      if (!userSettingsLoading) {
        return Object.fromEntries(Object.keys(USER_SETTINGS).map((settingName) => {
          const value = userSettingsData?.getUserSettings?.[settingName] 
          const defaultValue = USER_SETTINGS[settingName].defaultValue
          return [settingName, value ?? defaultValue]
        }))
      }
    }, [userSettingsData, userSettingsLoading])

    return {
        userSettingsLoading,
        userSettingsOrDefaults 
    }
}