import { gql, useLazyQuery, useQuery } from "@apollo/client";
import PlaintextTitleHeader from "../components/PlaintextTitleHeader";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";
import { LiteralTab } from "../utils/tabExtension";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";
import { decryptSymmetricString } from "../crypto/utils";
import { DraftMetadata as gqlDraftMetadata } from "../gql/graphql";
import DraftSelectModal from "../components/DraftSelectModal";
import RoomBox from "../components/RoomBox";
import { Socket } from "socket.io-client";

const GET_ALL_DRAFTS = gql`
  query GetAllDraftsForRoom {
    getAllDrafts {
      drafts {
        draftID
        encryptedTitle
        updatedAt
      }
    }
  }
`;

const GET_DRAFT = gql`
  query GetDraftForRoom($draftID: String!) {
    getDraft(request: { draftID: $draftID }) {
      draftID
      encryptedDraftHtml
    }
  }
`;

type DraftMetadata = {
  draftID: string;
  title: string | null;
  updatedAt: Date;
};

const extensions = [
  // @ts-ignore
  TextStyle.configure({ type: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    codeBlock: false,
    code: false,
  }),
  CharacterCount.configure(),
  LiteralTab.configure(),
  Placeholder.configure({
    placeholder: "Draft",
  }),
];

export default function RoomPage() {
  const { data } = useQuery(GET_ALL_DRAFTS, {
    fetchPolicy: "no-cache",
  });

  const { roomID } = useParams();

  const [getDraft, { data: draftData, loading: draftLoading }] = useLazyQuery(
    GET_DRAFT,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [socket, setSocket] = useState<Socket | null>(null);

  const [selectedDraftID, setSelectedDraftID] = useState<string | null>(null);

  const navigate = useNavigate();

  const { decryptionKey } = useUserInfo();
  const draftMetadataList = useMemo<DraftMetadata[]>(() => {
    if (!data || !decryptionKey) {
      return [];
    }
    return data.getAllDrafts.drafts.map((metadata: gqlDraftMetadata) => {
      return {
        draftID: metadata.draftID,
        title: decryptSymmetricString({
          encryptedPayload: metadata.encryptedTitle,
          secretKey: decryptionKey,
        }),
        updatedAt: new Date(metadata.updatedAt),
      };
    });
  }, [data, decryptionKey]);

  const decryptedDraftData = useMemo(() => {
    if (!draftData || !decryptionKey) {
      return;
    }
    const decryptedDraftHtml = draftData.getDraft?.encryptedDraftHtml
      ? decryptSymmetricString({
          secretKey: decryptionKey,
          encryptedPayload: draftData.getDraft.encryptedDraftHtml,
        })
      : null;
    return {
      draftHtml: decryptedDraftHtml,
    };
  }, [decryptionKey, draftData]);

  useEffect(() => {
    if (selectedDraftID) {
      getDraft({ variables: { draftID: selectedDraftID } });
    }
  }, [getDraft, selectedDraftID]);

  return (
    <div className="h-full">
      <PlaintextTitleHeader title="Joining room" />
      <DraftSelectModal
        open={true}
        setOpen={() => {}}
        roomID={roomID}
        dismissable={false}
      />
      <div className="flex flex-row" style={{ height: "calc(100% - 57px)" }}>
        <div
          className="flex flex-col justify-end"
          style={{ flex: 1, borderRightWidth: 2, borderColor: "gray" }}
        >
          <div>
            <RoomBox setSocket={setSocket} socket={socket} />
          </div>
        </div>
        <div style={{ flex: 2 }}></div>
      </div>
    </div>
  );
}
