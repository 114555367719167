import { Avatar, Button, Spacer } from "@nextui-org/react";
import { CommentMetadata } from "../gql/graphql";
import { useContext, useMemo, useState } from "react";
import { useUserInfo } from "../hooks/useUserInfo";
import {
  FeedActionType,
  FeedContext,
  FeedContextType,
} from "../providers/FeedProvider";
import { v4 } from "uuid";
import { Like1 } from "iconsax-react";
import { gql, useMutation } from "@apollo/client";
import { CommentActionType } from "./ActivityCommentThread";
import { Link, useNavigate } from "react-router-dom";

const LIKE_COMMENT = gql`
  mutation likeComment($commentID: String!, $commentLikeID: String!) {
    likeComment(
      request: { commentID: $commentID, commentLikeID: $commentLikeID }
    ) {
      success
    }
  }
`;

const UNLIKE_COMMENT = gql`
  mutation unlikeComment($commentID: String!) {
    unlikeComment(request: { commentID: $commentID }) {
      success
    }
  }
`;

const DELETE_COMMENT_FOR_ACTIVITY = gql`
  mutation deleteCommentForActivity($activityID: String!, $commentID: String!) {
    deleteComment(request: { activityID: $activityID, commentID: $commentID }) {
      success
    }
  }
`;

function ActivityFeedItemReplyComment({
  reply,
  activityID,
  parentCommentID,
  dispatch,
}: {
  reply: CommentMetadata;
  activityID: string;
  parentCommentID: string;
  dispatch: React.Dispatch<{
    type: CommentActionType;
    payload: any;
  }>;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const { username } = useUserInfo();
  const [likeComment] = useMutation(LIKE_COMMENT);
  const [unlikeComment] = useMutation(UNLIKE_COMMENT);
  const [deleteCommentForActivity] = useMutation(DELETE_COMMENT_FOR_ACTIVITY);
  const isLoggedIn = useMemo(() => !!username, [username]);
  const navigate = useNavigate();
  return (
    <div
      key={`reply-${reply.commentID}`}
      className="flex flex-row mt-2 mb-2"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link to={`/writer/${reply.username}`}>
        <Avatar src={reply.profileImageURL || ""} size="sm" />
      </Link>
      <div className="flex flex-col ml-2 w-full flex-1">
        <Link to={`/writer/${reply.username}`}>
          <div className="flex flex-row justify-between w-full">
            <p className="font-sans" style={{ fontSize: 12 }}>
              <span className="font-semibold">{reply.username}</span>
              <span className="ml-2">{reply.comment}</span>
            </p>
          </div>
        </Link>
        <div className="flex flex-row" style={{ fontSize: 12, color: "gray" }}>
          {reply.likes.length > 0 && (
            <div className="mr-2">
              {reply.likes.length === 1
                ? "1 like"
                : `${reply.likes.length} likes`}
            </div>
          )}
          {reply.username === username && (
            <div
              className="cursor-pointer"
              onClick={async () => {
                dispatch({
                  type: CommentActionType.DeleteReplyToComment,
                  payload: {
                    commentID: parentCommentID,
                    replyID: reply.commentID,
                    activityID: activityID,
                  },
                });
                await deleteCommentForActivity({
                  variables: {
                    activityID: activityID,
                    commentID: reply.commentID,
                  },
                });
              }}
            >
              <p>Delete</p>
            </div>
          )}
        </div>
      </div>
      {isHovered || reply.likes.find((c) => c.username === username) ? (
        <Button
          variant="light"
          size="sm"
          onClick={async () => {
            if (!isLoggedIn) {
              navigate("/login");
              return;
            }
            if (reply.likes.find((c) => c.username === username)) {
              dispatch({
                type: CommentActionType.UnlikeReplyToComment,
                payload: {
                  activityID,
                  commentID: parentCommentID,
                  replyID: reply.commentID,
                  replyLikeID: reply.likes.find((c) => c.username === username)
                    ?.commentLikeID,
                },
              });
              await unlikeComment({
                variables: {
                  commentID: reply.commentID,
                },
              });
              return;
            }
            const commentLikeID = v4();
            dispatch({
              type: CommentActionType.LikeReplyToComment,
              payload: {
                activityID,
                commentID: parentCommentID,
                replyID: reply.commentID,
                username,
                commentLikeID,
              },
            });
            await likeComment({
              variables: {
                activityID: activityID,
                commentID: reply.commentID,
                commentLikeID,
              },
            });
          }}
          isIconOnly
        >
          <Like1
            size={16}
            color={
              reply.likes.find((c) => c.username === username)
                ? "#7194A8"
                : "gray"
            }
            variant={
              reply.likes.find((c) => c.username === username)
                ? "Bold"
                : "Linear"
            }
          />
        </Button>
      ) : (
        <Spacer style={{ width: 32 }} />
      )}
    </div>
  );
}

export default ActivityFeedItemReplyComment;
