import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initializeActivity } from "../redux/activityStore";
import { useCallback } from "react";
import { gql, useMutation } from "@apollo/client";
import { RootState } from "../redux/store";

const CREATE_ACTIVITY = gql`
  mutation CreateActivity {
    createActivity {
      checksum
      activityID
    }
  }
`;

export function useCreateActivity() {
  const { draftID } = useParams();
  const checksum = useSelector((state: RootState) => state.activity.checksum);
  const dispatch = useDispatch();

  const [createActivityMutation] = useMutation(CREATE_ACTIVITY);

  const createActivityIfNoCurrent = useCallback(async () => {
    if (!checksum) {
      const { data } = await createActivityMutation({ variables: { draftID } });
      dispatch(
        initializeActivity({
          activityID: data.createActivity.activityID,
          checksum: data.createActivity.checksum,
        })
      );
    }
  }, [checksum, createActivityMutation, dispatch, draftID]);

  return {
    createActivityIfNoCurrent,
  };
}
