import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";
import { Switch } from "@nextui-org/react";
import { useUserSettings } from "../hooks/useUserSettings";
import { SettingName, USER_SETTINGS } from "../lib/userSettings";

const UPSERT_USER_SETTINGS = gql`
  mutation UpsertUserSettings(
    $autoRecord: Boolean
    $autoSave: Boolean
  ) {
    upsertUserSettings(
      request: {
        autoRecord: $autoRecord
        autoSave: $autoSave
      }
    ) {
      success
    }
  }
`;

function SingleSetting(props: {
    setting: SettingName,
    upsertMutation: any
    initialValue?: boolean
}) {
    const { setting, upsertMutation, initialValue } = props;
    const settingData = USER_SETTINGS[setting];
    const [localSetting, setLocalSetting] = useState<boolean>(!!initialValue);

    const setSetting = useCallback(async (value: boolean) => {
        setLocalSetting(value);
        await upsertMutation({
            variables: {
                [setting]: value
            }
        })
    }, [setLocalSetting, upsertMutation])
    return <div style={{ padding: 20 }}>
        <p
        className="font-sans"
        style={{ fontSize: 20, fontWeight: "bold", marginBottom: 15 }}
        >
            {settingData.displayName}
        </p>
        <p className="font-sans pb-2">
            {settingData.description}
        </p>
        <Switch
        isSelected={localSetting}
        onValueChange={setSetting}
        />
    </div>
}

function UserSettings() {
    const { userSettingsOrDefaults, userSettingsLoading } = useUserSettings();
    const [upsertUserSettings] = useMutation(UPSERT_USER_SETTINGS);
    return !userSettingsLoading ? <>
        {
            Object.keys(USER_SETTINGS).map((settingName) => (
            <SingleSetting
                key={settingName}
                setting={settingName as SettingName}
                upsertMutation={upsertUserSettings}
                initialValue={userSettingsOrDefaults?.[settingName]}
            />
        ))}
    </> : null;
}

export default UserSettings;