import { useNavigate, useParams } from "react-router-dom";
import PlaintextTitleHeader from "../components/PlaintextTitleHeader";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button } from "@nextui-org/react";
import {
  Area,
  AreaChart,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { useMemo, useState } from "react";
import { convertSecondsToString, parseHoursMinutes } from "../utils/string";
import Stripe from "../assets/stripe.png";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import { useUserInfo } from "../hooks/useUserInfo";
import UnauthenticatedHomePageHeader from "../components/UnauthenticatedHomePageHeader";

const GET_AGGREGATE_STATISTICS = gql`
  query GetAggregateStatistics($username: String!) {
    getAggregateStatistics(request: { username: $username }) {
      statistics {
        cumulativeWordsAdded
        cumulativeWordsRemoved
        cumulativeTimeSeconds
        date
      }
      numActivities
    }
  }
`;

const SEED_DATA = gql`
  mutation GetSeedData {
    seedData {
      success
    }
  }
`;

function CustomTooltip({ active, payload, label }: any) {
  if (active && payload.length > 0) {
    const { hours, minutes } = parseHoursMinutes(payload[0].value);
    return (
      <div
        className="font-sans"
        style={{
          flex: 1,
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          left: 80,
          top: -10,
          backgroundColor: "white",
        }}
      >
        <div className="flex flex-row">
          <div className="flex flex-col">
            <p>words</p>
            <p style={{ fontSize: 30, fontWeight: "bold", color: "#7194A8" }}>
              {payload[1].value[0]}
            </p>
            <div className="flex flex-row" style={{ fontSize: 12 }}>
              <p style={{ color: "#7194A8", fontWeight: "bold" }}>
                +{payload[1].value[1]}
              </p>
              <p style={{ marginLeft: 2, marginRight: 2 }}>/</p>
              <p
                style={{
                  color: "#aabfcb",
                  fontWeight: "bold",
                }}
              >
                -{payload[1].value[1] - payload[1].value[0]}
              </p>
            </div>
          </div>
          <div className="flex flex-col" style={{ marginLeft: 20 }}>
            <p>time</p>
            <p
              style={{
                fontSize: 30,
                fontWeight: "bold",
                color: "#8884d8",
                whiteSpace: "nowrap",
              }}
            >{`${hours}h ${minutes}m`}</p>
            <div className="flex flex-row">
              <p style={{ fontSize: 12, color: "gray", fontWeight: "bold" }}>
                {new Date(label).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

// keeping this around as prior art for a custom legend
function CustomizedLegend(args: any) {
  const typedArgs = args as { payload: { value: string; color: string }[] };
  return (
    <div className="flex flex-row justify-center">
      <defs>
        <pattern
          id="stripe"
          width="2"
          height="1"
          patternUnits="userSpaceOnUse"
          patternTransform="rotate(45)"
        >
          <rect width="1" height="1" fill="#d8a6a5" />
        </pattern>
      </defs>
      {typedArgs.payload.map((item) => (
        <div
          className="flex flex-row items-center"
          style={{ marginLeft: 10, marginRight: 10 }}
        >
          {item.value === "stripe" ? (
            <div
              style={{
                width: 20,
                height: 20,
                borderWidth: 1,
                borderColor: "#d4d4d8",
                marginRight: 5,
              }}
            >
              <img src={Stripe} alt="" />
            </div>
          ) : (
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: item.color,
                marginRight: 5,
              }}
            />
          )}
          <p className="font-sans">
            {item.value === "stripe" ? "Words removed" : item.value}
          </p>
        </div>
      ))}
    </div>
  );
}

function Statistics() {
  const { username } = useParams<{ username: string }>();
  const { userID } = useUserInfo();
  const navigate = useNavigate();
  const { data } = useQuery(GET_AGGREGATE_STATISTICS, {
    variables: { username },
    fetchPolicy: "no-cache",
  });
  const [chartHovered, setChartHovered] = useState(false);
  const processedData = useMemo(() => {
    if (!data) {
      return [];
    }
    const dataArray = data.getAggregateStatistics.statistics.map(function ({
      cumulativeTimeSeconds,
      cumulativeWordsRemoved,
      cumulativeWordsAdded,
      date,
    }: {
      cumulativeTimeSeconds: number;
      cumulativeWordsRemoved: number;
      cumulativeWordsAdded: number;
      date: string;
    }) {
      return {
        time: new Date(date).getTime(),
        cumulativeTimeSeconds,
        cumulativeWordsRemoved,
        cumulativeWordsAdded,
        cumulativeNetWords: cumulativeWordsAdded - cumulativeWordsRemoved,
        wordRange: [
          cumulativeWordsAdded - cumulativeWordsRemoved,
          cumulativeWordsAdded,
        ],
      };
    });
    if (dataArray.length === 0) {
      return [];
    }
    const lastDate = new Date(dataArray[dataArray.length - 1].time);
    const today = new Date();
    // include today's date if it's not already included
    if (lastDate.getDate() !== today.getDate()) {
      dataArray.push({
        time: today.getTime(),
        cumulativeTimeSeconds:
          dataArray[dataArray.length - 1].cumulativeTimeSeconds,
        cumulativeWordsRemoved:
          dataArray[dataArray.length - 1].cumulativeWordsRemoved,
        cumulativeWordsAdded:
          dataArray[dataArray.length - 1].cumulativeWordsAdded,
        cumulativeNetWords: dataArray[dataArray.length - 1].cumulativeNetWords,
        wordRange: [
          dataArray[dataArray.length - 1].cumulativeNetWords,
          dataArray[dataArray.length - 1].cumulativeWordsAdded,
        ],
      });
    }
    return dataArray;
  }, [data]);
  const firstElement = useMemo(() => {
    if (processedData.length === 0) {
      return null;
    }
    return processedData[0];
  }, [processedData]);
  const lastElement = useMemo(() => {
    if (processedData.length === 0) {
      return null;
    }
    return processedData[processedData.length - 1];
  }, [processedData]);
  return (
    <div>
      {userID ? (
        <PlaintextTitleHeader title={`${username}'s writing history`} />
      ) : (
        <UnauthenticatedHomePageHeader />
      )}
      <div style={{ padding: 20 }}>
        <Button
          className="font-sans"
          color="primary"
          startContent={<ArrowLeft />}
          size="sm"
          onClick={() => {
            navigate(`/writer/${username}`);
          }}
        >
          Back to {username}'s profile
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 30,
        }}
      >
        {processedData.length === 0 && (
          <div className="font-sans">
            <p>No history data</p>
          </div>
        )}
        {processedData.length > 0 && (
          <div
            className="relative"
            onMouseEnter={() => setChartHovered(true)}
            onMouseLeave={() => setChartHovered(false)}
          >
            <ComposedChart
              width={800}
              height={300}
              data={processedData}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              defaultShowTooltip={true}
            >
              <defs>
                <pattern
                  id="stripe"
                  width="8"
                  height="4"
                  patternUnits="userSpaceOnUse"
                  patternTransform="rotate(45)"
                >
                  <rect width="2" height="2" fill="#7194A8" />
                </pattern>
              </defs>
              <Line
                dataKey="cumulativeTimeSeconds"
                stroke="#8884d8"
                dot={false}
                yAxisId="time"
                type="basis"
                name="Writing time"
                strokeWidth={3}
                activeDot={false}
              />
              <XAxis
                dataKey="time"
                type="number"
                domain={[firstElement, lastElement]}
                ticks={[firstElement.time, lastElement.time]}
                tickFormatter={(time) => new Date(time).toLocaleDateString()}
                padding={{ left: 50, right: 20 }}
              />
              <Area
                dataKey="wordRange"
                fill="url(#stripe)"
                stroke="#7194A8"
                dot={false}
                yAxisId="words"
                type="basis"
                name="Net words"
                strokeWidth={3}
                activeDot={false}
              />
              <Line
                dataKey="custom"
                stroke="#7194A8"
                dot={false}
                yAxisId="time"
                type="basis"
                name="stripe"
                activeDot={false}
              />
              <YAxis
                yAxisId="time"
                axisLine={false}
                tickCount={0}
                domain={[0, lastElement.cumulativeTimeSeconds * 1.4]}
              />
              <YAxis
                yAxisId="words"
                axisLine={false}
                orientation="right"
                tickCount={0}
              />
              <Tooltip content={<CustomTooltip />} />
            </ComposedChart>
            {lastElement && !chartHovered && (
              <div className="absolute" style={{ left: 90, top: 0 }}>
                <div className="flex flex-row font-sans">
                  <div className="flex flex-col">
                    <p>words</p>
                    <p
                      style={{
                        fontSize: 30,
                        fontWeight: "bold",
                        color: "#7194A8",
                      }}
                    >
                      {lastElement.cumulativeNetWords}
                    </p>
                    <div className="flex flex-row" style={{ fontSize: 12 }}>
                      <p style={{ color: "#7194A8", fontWeight: "bold" }}>
                        +{lastElement.cumulativeWordsAdded}
                      </p>
                      <p
                        style={{
                          marginLeft: 2,
                          marginRight: 2,
                        }}
                      >
                        /
                      </p>
                      <p
                        style={{
                          color: "#aabfcb",
                          fontWeight: "bold",
                        }}
                      >
                        -{lastElement.cumulativeWordsRemoved}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col" style={{ marginLeft: 20 }}>
                    <p>time</p>
                    <p
                      style={{
                        fontSize: 30,
                        fontWeight: "bold",
                        color: "#8884d8",
                      }}
                    >{`${
                      parseHoursMinutes(lastElement.cumulativeTimeSeconds).hours
                    }h ${
                      parseHoursMinutes(lastElement.cumulativeTimeSeconds)
                        .minutes
                    }m`}</p>
                    <div className="flex flex-row">
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          color: "gray",
                        }}
                      >
                        all time
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Statistics;
