import { Avatar, Button, Input, Spacer } from "@nextui-org/react";
import { CommentMetadata } from "../gql/graphql";
import { useMemo, useState } from "react";
import { useUserInfo } from "../hooks/useUserInfo";
import { gql, useMutation } from "@apollo/client";
import { v4 } from "uuid";
import { Like1, Send } from "iconsax-react";
import ActivityFeedItemReplyComment from "./ActivityFeedItemReplyComment";
import { CommentActionType } from "./ActivityCommentThread";
import { Link, useNavigate } from "react-router-dom";

const DELETE_COMMENT_FOR_ACTIVITY = gql`
  mutation deleteCommentForActivity($activityID: String!, $commentID: String!) {
    deleteComment(request: { activityID: $activityID, commentID: $commentID }) {
      success
    }
  }
`;

const LIKE_COMMENT = gql`
  mutation likeComment($commentID: String!, $commentLikeID: String!) {
    likeComment(
      request: { commentID: $commentID, commentLikeID: $commentLikeID }
    ) {
      success
    }
  }
`;

const UNLIKE_COMMENT = gql`
  mutation unlikeComment($commentID: String!) {
    unlikeComment(request: { commentID: $commentID }) {
      success
    }
  }
`;

const REPLY_TO_COMMENT = gql`
  mutation replyToComment(
    $commentToReplyToID: String!
    $commentID: String!
    $comment: String!
  ) {
    replyToComment(
      request: {
        commentToReplyToID: $commentToReplyToID
        commentID: $commentID
        comment: $comment
      }
    ) {
      success
    }
  }
`;

function ActivityFeedItemComment({
  comment,
  activityID,
  dispatch,
}: {
  comment: CommentMetadata;
  activityID: string;
  dispatch: React.Dispatch<{
    type: CommentActionType;
    payload: any;
  }>;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const { username, profilePhotoDownloadURL } = useUserInfo();
  const [isReplying, setIsReplying] = useState(false);
  const [deleteCommentForActivity] = useMutation(DELETE_COMMENT_FOR_ACTIVITY);
  const [likeComment] = useMutation(LIKE_COMMENT);
  const [unlikeComment] = useMutation(UNLIKE_COMMENT);
  const [replyToComment] = useMutation(REPLY_TO_COMMENT);
  const [replyMessage, setReplyMessage] = useState<string>("");
  const isLoggedIn = useMemo(() => {
    return !!username;
  }, [username]);
  const navigate = useNavigate();

  return (
    <div key={comment.commentID} className="mt-2">
      <div
        className="flex flex-row w-full"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Link to={`/writer/${comment.username}`}>
          <Avatar src={comment.profileImageURL || ""} size="sm" />
        </Link>
        <div className="flex flex-col ml-2 w-full flex-1">
          <Link to={`/writer/${comment.username}`}>
            <div className="flex flex-row justify-between w-full">
              <p className="font-sans" style={{ fontSize: 12 }}>
                <span className="font-semibold">{comment.username}</span>
                <span className="ml-2">{comment.comment}</span>
              </p>
            </div>
          </Link>
          <div
            className="flex flex-row"
            style={{ fontSize: 12, color: "gray" }}
          >
            {comment.likes.length > 0 && (
              <div className="mr-2">
                {comment.likes.length === 1
                  ? "1 like"
                  : `${comment.likes.length} likes`}
              </div>
            )}
            <div
              className="mr-2 cursor-pointer"
              onClick={() => {
                setIsReplying(!isReplying);
              }}
            >
              <p>
                {comment.replies?.length === 0
                  ? "Reply"
                  : `${comment.replies?.length} repl${
                      comment.replies?.length === 1 ? "y" : "ies"
                    }`}
              </p>
            </div>
            {comment.username === username && (
              <div
                className="cursor-pointer"
                onClick={async () => {
                  dispatch({
                    type: CommentActionType.RemoveActivityComment,
                    payload: {
                      activityID: activityID,
                      commentID: comment.commentID,
                    },
                  });
                  await deleteCommentForActivity({
                    variables: {
                      activityID: activityID,
                      commentID: comment.commentID,
                    },
                  });
                }}
              >
                <p>Delete</p>
              </div>
            )}
          </div>
        </div>

        <div className="flex-row flex">
          {isHovered || comment.likes.find((c) => c.username === username) ? (
            <Button
              variant="light"
              size="sm"
              onClick={async () => {
                if (!isLoggedIn) {
                  navigate("/login");
                  return;
                }

                if (comment.likes.find((c) => c.username === username)) {
                  dispatch({
                    type: CommentActionType.RemoveActivityCommentLike,
                    payload: {
                      activityID: activityID,
                      commentID: comment.commentID,
                      commentLikeID: comment.likes.find(
                        (c) => c.username === username
                      )?.commentLikeID,
                    },
                  });
                  await unlikeComment({
                    variables: {
                      commentID: comment.commentID,
                    },
                  });
                  return;
                }
                const commentLikeID = v4();
                dispatch({
                  type: CommentActionType.AddActivityCommentLike,
                  payload: {
                    activityID: activityID,
                    commentID: comment.commentID,
                    username,
                    commentLikeID,
                  },
                });
                await likeComment({
                  variables: {
                    activityID: activityID,
                    commentID: comment.commentID,
                    commentLikeID,
                  },
                });
              }}
              isIconOnly
            >
              <Like1
                size={16}
                color={
                  comment.likes.find((c) => c.username === username)
                    ? "#7194A8"
                    : "gray"
                }
                variant={
                  comment.likes.find((c) => c.username === username)
                    ? "Bold"
                    : "Linear"
                }
              />
            </Button>
          ) : (
            <Spacer style={{ width: 32 }} />
          )}
        </div>
      </div>
      {isReplying && (
        <div className="flex flex-col" style={{ marginLeft: 40 }}>
          <div className="flex flex-col">
            {comment.replies?.map((reply) => (
              <ActivityFeedItemReplyComment
                key={`replycomment-${reply.commentID}`}
                reply={reply}
                activityID={activityID}
                parentCommentID={comment.commentID}
                dispatch={dispatch}
              />
            ))}
          </div>
          <div className="flex flex-row">
            <Input
              variant="underlined"
              classNames={{
                inputWrapper: ["h-[24px]"],
              }}
              placeholder="Reply"
              value={replyMessage}
              style={{ maxHeight: 24, fontSize: 12 }}
              onValueChange={(value) => setReplyMessage(value)}
            />
            <Button
              size="sm"
              className="font-sans"
              variant="light"
              isIconOnly
              onClick={async () => {
                if (!isLoggedIn) {
                  navigate("/login");
                  return;
                }
                if (!replyMessage) return;
                const replyID = v4();
                dispatch({
                  type: CommentActionType.ReplyToComment,
                  payload: {
                    activityID: activityID,
                    commentID: comment.commentID,
                    replyID: replyID,
                    comment: replyMessage,
                    username,
                    profileImageURL: profilePhotoDownloadURL,
                  },
                });
                setReplyMessage("");
                await replyToComment({
                  variables: {
                    activityID: activityID,
                    commentToReplyToID: comment.commentID,
                    commentID: replyID,
                    comment: replyMessage,
                  },
                });
              }}
            >
              <Send color="#7194A8" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ActivityFeedItemComment;
