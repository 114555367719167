import {
  Button,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  Textarea,
  Spacer,
} from "@nextui-org/react";
import { useMemo, useState } from "react";
import { ActivityStatus } from "../gql/graphql";
import { Play } from "iconsax-react";
import { useDispatch } from "react-redux";
import {
  incrementSecondsElapsed,
  setTitleAndDescription,
  updateChecksum,
} from "../redux/activityStore";
import { convertSecondsToString } from "../utils/string";
import { useInterval } from "../hooks/useInterval";
import { LineChart, Line, XAxis } from "recharts";
import { useNavigate } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";

function RecordingStatus(props: {
  currentActivityData: any;
  createActivityIfNoCurrent: any;
}) {
  const { currentActivityData, createActivityIfNoCurrent } = props;
  const {
    status,
    secondsElapsed,
    wordsAdded,
    wordsDeleted,
    activityID,
    loaded,
    secondsElapsedArray,
    wordDifferentialArray,
    description,
    title,
    checksum,
    outOfSync,
    pauseActivity,
    unpauseActivity,
    finishActivity,
    publishActivity,
    deleteActivity,
    debouncedModifyFinishedActivityMutation,
  } = currentActivityData;

  const isRecording = useMemo(() => status === ActivityStatus.Active, [status]);
  const isPaused = useMemo(() => status === ActivityStatus.Paused, [status]);
  const dispatch = useDispatch();
  const { username } = useUserInfo();

  const [divHovered, setDivHovered] = useState(false);
  const [mouseHovered, setMouseHovered] = useState(false);
  const [playHovered, setPlayHovered] = useState(false);
  const [stopHovered, setStopHovered] = useState(false);
  const [titleEmptyError, setTitleEmptyError] = useState(false);
  const navigate = useNavigate();
  const baseText = useMemo(() => {
    if (status === null) {
      return "REC";
    } else if (status === ActivityStatus.Paused) {
      return "PAUSE";
    } else {
      return "PAUSE";
    }
  }, [status]);

  const baseColor = useMemo(() => {
    if (status === null) {
      if (mouseHovered) {
        return "white";
      }
      return "#d4d4d8";
    } else if (status === ActivityStatus.Paused) {
      return "#f21161";
    } else {
      if (mouseHovered) {
        return "white";
      }
      return "#f21161";
    }
  }, [mouseHovered, status]);
  const sessionActive = useMemo(() => {
    if (status === null) {
      return false;
    }
    return ["Active", "Paused"].includes(status);
  }, [status]);

  const shouldShowRecordButton = useMemo(() => {
    if (status === null) {
      return true;
    }
    return ["Active", "Paused"].includes(status);
  }, [status]);
  const shouldShowPublishingModal = useMemo(() => {
    if (status === null) {
      return false;
    }
    return status === ActivityStatus.Unpublished;
  }, [status]);

  // incrementing activity timer
  useInterval(
    () => {
      dispatch(incrementSecondsElapsed());
    },
    status === ActivityStatus.Active ? 1000 : null
  );

  if (!loaded) {
    return null;
  }

  return (
    <div
      className="flex flex-col items-center position-relative"
      onMouseEnter={() => {
        setDivHovered(true);
      }}
      onMouseLeave={() => {
        setDivHovered(false);
      }}
    >
      <Modal isOpen={outOfSync} isDismissable={false}>
        <ModalHeader>Activity out of sync</ModalHeader>
        <ModalContent>
          <div className="font-sans flex-col" style={{ padding: 20 }}>
            <p>
              Your draft is out of sync with the server. Reload the page to
              resync.
            </p>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </Button>
          </div>
        </ModalContent>
      </Modal>
      {shouldShowRecordButton && (
        <div
          style={{ position: "relative" }}
          onMouseEnter={() => {
            setMouseHovered(true);
          }}
          onMouseLeave={() => {
            setMouseHovered(false);
          }}
        >
          <Button
            variant={"ghost"}
            color={
              isRecording || isPaused || mouseHovered ? "danger" : "default"
            }
            onPress={async () => {
              if (status === null) {
                await createActivityIfNoCurrent();
              } else if (status === ActivityStatus.Active) {
                await pauseActivity();
                // not sure why needed
                setPlayHovered(false);
              }
            }}
            style={{
              borderColor: status ? "#f21161" : undefined,
              height: 35,
            }}
          >
            <p
              className="font-sans"
              style={{
                color: baseColor,
                fontWeight: "bold",
                ...(status
                  ? {}
                  : {
                      marginLeft: "8.23px",
                      marginRight: "8.23px",
                    }),
              }}
            >
              {baseText}
            </p>
          </Button>
          {isPaused && (
            <div
              className="absolute top-0 flex flex-row justify-between"
              style={{ width: "80px", backgroundColor: "white" }}
            >
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: playHovered ? "#11a250" : "white",
                  borderRadius: "12px",
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                  borderWidth: "2px",
                  borderColor: "#11a250",
                }}
                onMouseEnter={() => {
                  setPlayHovered(true);
                }}
                onMouseLeave={() => {
                  setPlayHovered(false);
                }}
                onClick={async () => {
                  await unpauseActivity();
                }}
              >
                <Play
                  variant="Linear"
                  color={playHovered ? "white" : "#11a250"}
                  style={{ fill: playHovered ? "white" : "#11a250" }}
                  size={12}
                />
              </div>
              <div
                style={{
                  width: "35px",
                  height: "35px",
                  backgroundColor: stopHovered ? "#f21161" : "white",
                  borderRadius: "12px",
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                  borderWidth: "2px",
                  borderColor: "#f21161",
                }}
                onMouseEnter={() => {
                  setStopHovered(true);
                }}
                onMouseLeave={() => {
                  setStopHovered(false);
                }}
                onClick={async () => {
                  await finishActivity();
                }}
              >
                <div
                  style={{
                    width: 8,
                    height: 8,
                    backgroundColor: stopHovered ? "white" : "#f21161",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {sessionActive && divHovered && (
        <div
          className="flex flex-col items-center mt-2 absolute"
          style={{
            top: 50,
            right: 44,
            backgroundColor: "#f5f5f5",
            padding: 12,
            borderRadius: 12,
          }}
        >
          <p
            className="font-mono"
            style={{
              fontSize: "2em",
              color: isPaused ? "gray" : undefined,
            }}
          >
            {convertSecondsToString(secondsElapsed)}
          </p>
          <p className="font-mono text-lg" style={{ fontWeight: 700 }}>
            <span style={{ color: "#12A150" }} className="mr-2">
              +{wordsAdded}
            </span>
            /
            <span style={{ color: "#FF715B" }} className="ml-2">
              -{wordsDeleted}
            </span>
          </p>
        </div>
      )}
      {shouldShowPublishingModal && (
        <div
          className="font-sans absolute"
          style={{
            top: 10,
            right: 50,
            backgroundColor: "white",
            padding: 12,
          }}
        >
          <Input
            placeholder="Session title (required)"
            classNames={{
              input: ["font-sans font-semibold text-lg"],
              inputWrapper: ["shadow-none"],
            }}
            isInvalid={titleEmptyError}
            errorMessage={titleEmptyError ? "Title is required" : undefined}
            value={title ?? ""}
            onValueChange={async (value) => {
              setTitleEmptyError(false);
              dispatch(setTitleAndDescription({ title: value }));
              const response = await debouncedModifyFinishedActivityMutation({
                variables: {
                  activityID,
                  title: value,
                  checksum,
                },
              });
              if (response) {
                dispatch(
                  updateChecksum(response.data.modifyFinishedActivity.checksum)
                );
              }
            }}
            isRequired
          />
          <Spacer y={2} />
          <Textarea
            placeholder="Description"
            classNames={{ input: ["font-sans"] }}
            maxRows={20}
            value={description ?? ""}
            onValueChange={async (value) => {
              dispatch(setTitleAndDescription({ description: value }));
              const response = await debouncedModifyFinishedActivityMutation({
                variables: {
                  activityID,
                  description: value,
                  checksum,
                },
              });
              if (response) {
                dispatch(
                  updateChecksum(response.data.modifyFinishedActivity.checksum)
                );
              }
            }}
          />
          <div className="flex flex-col items-left mt-2">
            <p
              className="font-mono"
              style={{ fontSize: "2em", color: isPaused ? "gray" : undefined }}
            >
              {convertSecondsToString(secondsElapsed)}
            </p>
            <p className="font-mono text-lg" style={{ fontWeight: 700 }}>
              <span style={{ color: "#12A150" }} className="mr-2">
                +{wordsAdded}
              </span>
              /
              <span style={{ color: "#FF715B" }} className="ml-2">
                -{wordsDeleted}
              </span>
            </p>
            {secondsElapsedArray && wordDifferentialArray && (
              <div>
                <LineChart
                  width={225}
                  height={200}
                  data={secondsElapsedArray.map(function (
                    x: number,
                    i: number
                  ) {
                    return { time: x, words: wordDifferentialArray[i] };
                  })}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <Line
                    type="monotone"
                    dataKey="words"
                    stroke="#8884d8"
                    dot={false}
                  />
                  <XAxis dataKey="time" min={0} type="number" />
                </LineChart>
              </div>
            )}
          </div>
          <div className="flex flex-row justify-between">
            <Button
              className="font-sans"
              onClick={async () => {
                await deleteActivity();
                setMouseHovered(false);
              }}
            >
              Discard
            </Button>
            <Button
              className="font-sans"
              onClick={async () => {
                if (!title) {
                  setTitleEmptyError(true);
                  return;
                }
                const response = await publishActivity();
                if (!response.errors) {
                  setMouseHovered(false);
                  navigate(`/writer/${username}`);
                }
              }}
              color="primary"
            >
              Publish
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RecordingStatus;
