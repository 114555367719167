import ActivityFeedItem from "./ActivityFeedItem";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useMediaQuery } from "react-responsive";
import { FeedContext, FeedContextType } from "../providers/FeedProvider";
import { InfoCircle } from "iconsax-react";
import ActivityGrid from "./ActivityGrid";
import { Switch } from "@nextui-org/react";

function ProfileActivityFeed({
  maxHeight,
  prefixItem,
  leftAligned = false,
}: {
  children?: React.ReactNode;
  maxHeight: string;
  prefixItem?: React.ReactNode;
  leftAligned?: boolean;
}) {
  const [showWordLog, setShowWordLog] = useState(false);
  const [showTimeTooltip, setShowTimeTooltip] = useState(false);
  const { feed, fetchMore } = useContext<FeedContextType>(FeedContext);
  const isMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });
  const parentRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: feed.activities?.length,
    getScrollElement: () => {
      return parentRef.current;
    },
    estimateSize: () => 472,
  });
  const virtualItems = rowVirtualizer.getVirtualItems();
  const lastVirtualItemsIndex = useMemo(() => {
    return virtualItems[virtualItems.length - 1]?.index;
  }, [virtualItems]);
  useEffect(() => {
    if (lastVirtualItemsIndex) {
      if (
        feed &&
        lastVirtualItemsIndex === feed.activities.length - 1 &&
        feed.hasMore
      ) {
        fetchMore();
      }
    }
  }, [feed, feed.activities?.length, fetchMore, lastVirtualItemsIndex]);

  return (
    <div
      className="flex flex-col relative"
      style={{
        maxHeight,
        height: maxHeight,
        minHeight: 500,
        overflow: "auto",
        width: "100%",
        alignItems: leftAligned ? "flex-start" : "center",
      }}
      ref={parentRef}
    >
      <div className="flex flex-row">
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            minHeight: `${rowVirtualizer.getTotalSize()}px`,
            width: isMobile ? "100%" : 500,
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${
                rowVirtualizer.getVirtualItems()[0]?.start ?? 0
              }px)`,
            }}
          >
            {prefixItem}
            {rowVirtualizer.getVirtualItems().map((virtualItem) => (
              <div
                key={feed.activities[virtualItem.index].activityID}
                data-index={virtualItem.index}
                ref={rowVirtualizer.measureElement}
              >
                <ActivityFeedItem
                  activityMetadata={feed.activities[virtualItem.index]}
                />
              </div>
            ))}
          </div>
        </div>
        <div style={{ position: "fixed", left: "calc(50vw + 270px)" }}>
          <div
            className="flex flex-row justify-between w-full"
            style={{
              marginBottom: 10,
              marginTop: 30,
            }}
          >
            <p className="font-sans font-semibold text-lg">Activity grid</p>{" "}
          </div>

          <div
            className="flex flex-row items-center"
            style={{ marginBottom: 24 }}
          >
            <div className="flex flex-row">
              <p className="font-sans" style={{ fontSize: 12 }}>
                Time
              </p>
              <div
                style={{
                  position: "relative",
                  marginLeft: 4,
                }}
                onMouseEnter={() => {
                  setShowTimeTooltip(true);
                }}
                onMouseLeave={() => {
                  setShowTimeTooltip(false);
                }}
                onFocus={() => setShowTimeTooltip(true)}
                onBlur={() => setShowTimeTooltip(false)}
              >
                <InfoCircle
                  style={{
                    width: 16,
                    height: 16,
                    color: "gray",
                  }}
                />
                {showTimeTooltip && (
                  <div
                    className="font-sans"
                    style={{
                      position: "absolute",
                      width: 100,
                      backgroundColor: "white",
                      fontSize: 10,
                      padding: 5,
                      zIndex: 1,
                      left: -40,
                      top: 20,
                    }}
                  >
                    Time is measured from your recorded sessions.
                  </div>
                )}
              </div>
            </div>
            <div style={{ marginLeft: 8 }}>
              <Switch
                size="sm"
                isSelected={showWordLog}
                onValueChange={setShowWordLog}
                classNames={{
                  wrapper: [
                    "group-data-[selected=true]:bg-secondary",
                    "bg-primary",
                  ],
                }}
              />
            </div>
            <p className="font-sans" style={{ fontSize: 12 }}>
              Words
            </p>
          </div>
          <ActivityGrid showWordLog={showWordLog} vertical={true} />
        </div>
      </div>
    </div>
  );
}

export default ProfileActivityFeed;
