import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Textarea } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Socket } from "socket.io-client";
import { useDebouncedCallback } from "use-debounce";

const GET_ROOM_DATA = gql`
  query getRoomDataPromptEditor($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      prompt
    }
  }
`;

const UPDATE_ROOM_PROMPT = gql`
  mutation updateRoomPrompt($request: UpdateRoomPromptRequest!) {
    updateRoomPrompt(request: $request) {
      success
    }
  }
`;
const GENERATE_PROMPT = gql`
  query GeneratePrompt {
    generatePrompt {
      prompt
    }
  }
`;

export default function PromptEditor({ socket }: { socket: Socket | null }) {
  const { roomID } = useParams();
  const { data } = useQuery(GET_ROOM_DATA, {
    variables: { roomID },
    onCompleted: (data) => {
      setPrompt(data.getRoomData.prompt);
    },
  });
  const [updateRoomPrompt] = useMutation(UPDATE_ROOM_PROMPT);
  const [generatePrompt] = useLazyQuery(GENERATE_PROMPT, {
    fetchPolicy: "no-cache",
  });
  const debouncedUpdateRoomPrompt = useDebouncedCallback(updateRoomPrompt, 300);
  const [prompt, setPrompt] = useState("");

  useEffect(() => {
    if (socket && !socket.hasListeners("prompt_update")) {
      socket.on("prompt_update", (...args) => {
        setPrompt(args[0].message);
      });
    }
  }, [socket]);

  return (
    <div
      className="font-sans"
      style={{ padding: 24, borderBottom: "1px solid #e0e0e0" }}
    >
      <div
        className="flex flex-row items-start justify-between"
        style={{ paddingBottom: 16 }}
      >
        <p style={{ fontSize: 14 }}>Prompt</p>
        <Button
          size="sm"
          style={{ height: 24, fontSize: 14 }}
          onClick={async () => {
            const { data } = await generatePrompt();
            if (data) {
              setPrompt(data.generatePrompt.prompt);
              updateRoomPrompt({
                variables: {
                  request: { roomID, prompt: data.generatePrompt.prompt },
                },
              });
              socket?.emit("prompt_update", {
                prompt: data.generatePrompt.prompt,
                roomID,
              });
            }
          }}
        >
          <p style={{ fontSize: 14 }}>Generate</p>
        </Button>
      </div>
      <Textarea
        value={prompt}
        onChange={(e) => {
          setPrompt(e.target.value);
          debouncedUpdateRoomPrompt({
            variables: { request: { roomID, prompt: e.target.value } },
          });
          socket?.emit("prompt_update", {
            prompt: e.target.value,
            roomID,
          });
        }}
      />
    </div>
  );
}
