import { useMemo, useState } from "react";
import { CommentMetadata } from "../gql/graphql";
import { Button, Input } from "@nextui-org/react";
import { Send } from "iconsax-react";
import { v4 } from "uuid";
import ActivityFeedItemComment from "./ActivityFeedItemComment";
import { useUserInfo } from "../hooks/useUserInfo";
import { gql, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

export enum CommentActionType {
  AddActivityComment = "ADD_ACTIVITY_COMMENT",
  RemoveActivityComment = "REMOVE_ACTIVITY_COMMENT",
  UpdateActivityComment = "UPDATE_ACTIVITY_COMMENT",
  AddActivityCommentLike = "ADD_ACTIVITY_COMMENT_LIKE",
  RemoveActivityCommentLike = "REMOVE_ACTIVITY_COMMENT_LIKE",
  ReplyToComment = "REPLY_TO_COMMENT",
  DeleteReplyToComment = "DELETE_REPLY_TO_COMMENT",
  LikeReplyToComment = "LIKE_REPLY_TO_COMMENT",
  UnlikeReplyToComment = "UNLIKE_REPLY_TO_COMMENT",
}

const CREATE_COMMENT_FOR_ACTIVITY = gql`
  mutation createCommentForActivity(
    $activityID: String!
    $commentID: String!
    $comment: String!
  ) {
    commentOnActivity(
      request: {
        activityID: $activityID
        commentID: $commentID
        comment: $comment
      }
    ) {
      success
    }
  }
`;

function ActivityCommentThread({
  comments,
  activityID,
  dispatch,
  forceStartCommentIndex,
  hideCollapseComments,
}: {
  comments: CommentMetadata[];
  activityID: string;
  dispatch: React.Dispatch<{
    type: CommentActionType;
    payload: any;
  }>;
  forceStartCommentIndex?: number;
  hideCollapseComments?: boolean;
}) {
  const [commentInput, setCommentInput] = useState("");
  const [createCommentForActivity] = useMutation(CREATE_COMMENT_FOR_ACTIVITY);
  const { userID, username, profilePhotoDownloadURL } = useUserInfo();
  const navigate = useNavigate();

  const [startCommentIndex, setStartCommentIndex] = useState(
    forceStartCommentIndex ?? Math.max(comments.length - 1, 0)
  );
  const showAllComments = useMemo(() => {
    return startCommentIndex === 0;
  }, [startCommentIndex]);
  const moreThanOneCommentShown = useMemo(() => {
    return comments.length - 1 > startCommentIndex;
  }, [comments, startCommentIndex]);
  const isLoggedIn = useMemo(() => {
    return !!userID;
  }, [userID]);
  return (
    <div className="flex flex-col">
      {!showAllComments && comments.length > 0 && (
        <div className="flex flex-row justify-start">
          <Button
            size="sm"
            variant="light"
            className="font-sans"
            onClick={() => {
              setStartCommentIndex(0);
            }}
            style={{ marginLeft: -12, color: "gray" }}
          >
            {comments.length === 1
              ? `View 1 comment`
              : `View all ${comments.length} comments`}
          </Button>
        </div>
      )}
      {moreThanOneCommentShown &&
        comments.length > 0 &&
        !hideCollapseComments && (
          <div className="flex flex-row justify-start">
            <Button
              size="sm"
              variant="light"
              className="font-sans"
              onClick={() => {
                setStartCommentIndex(comments.length - 1);
              }}
              style={{ marginLeft: -12, color: "gray" }}
            >
              <p>Collapse comments</p>
            </Button>
          </div>
        )}
      <div className="font-sans flex flex-col">
        {comments?.slice(startCommentIndex, comments.length).map((comment) => (
          <ActivityFeedItemComment
            key={`comment-${comment.commentID}`}
            comment={comment}
            activityID={activityID}
            dispatch={dispatch}
          />
        ))}
      </div>
      <div className="mt-2 flex flex-row ">
        <Input
          placeholder="Comment"
          value={commentInput}
          onValueChange={(value) => setCommentInput(value)}
          size="sm"
          className="font-sans"
          classNames={{
            inputWrapper: ["h-[24px]"],
          }}
          variant="underlined"
          style={{ maxHeight: 24, fontSize: 12 }}
        />
        <Button size="sm" className="font-sans" variant="light" isIconOnly>
          <Send
            color="#7194A8"
            onClick={async () => {
              if (!isLoggedIn) {
                navigate("/login");
                return;
              }
              if (!commentInput) {
                return;
              }
              const commentID = v4();
              dispatch({
                type: CommentActionType.AddActivityComment,
                payload: {
                  activityID: activityID,
                  commentID,
                  comment: commentInput,
                  username,
                  profileImageURL: profilePhotoDownloadURL,
                },
              });
              await createCommentForActivity({
                variables: {
                  activityID,
                  commentID,
                  comment: commentInput,
                },
              });
              setCommentInput("");
            }}
          />
        </Button>
      </div>
    </div>
  );
}

export default ActivityCommentThread;
