import { Avatar, Button, Input } from "@nextui-org/react";
import { ArrowDown2, ArrowUp2, Copy, CopySuccess } from "iconsax-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";
import { gql, useQuery } from "@apollo/client";
import { io, Socket } from "socket.io-client";
import { useInterval } from "../hooks/useInterval";

const VERIFY_ROOM = gql`
  query VerifyRoom($roomID: String!) {
    verifyRoom(request: { roomID: $roomID })
  }
`;

const GET_ROOM_DATA = gql`
  query GetRoomDataRoomBox($roomID: String!) {
    getRoomData(request: { roomID: $roomID }) {
      wordsWritten
    }
  }
`;

type RoomState = {
  usersInRoom: { username: string; profilePhotoDownloadURL: string | null }[];
};

const COLOR_DURATION = 200;

export default function RoomBox({
  socket,
  setSocket,
}: {
  socket: Socket | null;
  setSocket: (socket: Socket | null) => void;
}) {
  const { roomID, draftID } = useParams();
  const { userID } = useUserInfo();
  const [inviteLinkCopied, setInviteLinkCopied] = useState(false);
  const [roomState, setRoomState] = useState<RoomState>({
    usersInRoom: [],
  });
  const { data, loading } = useQuery(VERIFY_ROOM, { variables: { roomID } });
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [wordsWritten, setWordsWritten] = useState(0);
  useQuery(GET_ROOM_DATA, {
    variables: { roomID },
    onCompleted: (data) => setWordsWritten(data.getRoomData.wordsWritten),
  });
  const [wordsAddedUsers, setWordsAddedUsers] = useState<{
    [userID: string]: Date;
  }>({});
  const [wordsDeletedUsers, setWordsDeletedUsers] = useState<{
    [userID: string]: Date;
  }>({});

  const navigate = useNavigate();
  const [joinedRoom, setJoinedRoom] = useState(false);

  useEffect(() => {
    if (!socket) {
      const newSocket = io(
        `${process.env.REACT_APP_API_ROOT}:${process.env.REACT_APP_EXTERNAL_WS_PORT}`,
        {
          transports: ["websocket"],
        }
      );
      setSocket(newSocket);
    }
  });

  useEffect(() => {
    if (socket) {
      socket.on("room_update", (...args) => {
        if (args[0].type === "room_state") {
          setRoomState({ ...roomState, usersInRoom: args[0].usersInRoom });
        } else if (args[0].type === "user_left") {
          setRoomState({
            ...roomState,
            usersInRoom: roomState.usersInRoom.filter(
              (user) => user.username !== args[0].username
            ),
          });
        } else if (args[0].type === "awareness_activity") {
          if (args[0].content === "word_added") {
            setWordsAddedUsers({
              ...wordsAddedUsers,
              [args[0].username]: new Date(),
            });
          } else if (args[0].content === "word_deleted") {
            setWordsDeletedUsers({
              ...wordsDeletedUsers,
              [args[0].username]: new Date(),
            });
          }
        } else if (args[0].type === "words_written") {
          setWordsWritten(args[0].wordsWritten);
        }
      });
      if (!joinedRoom && userID) {
        socket.emit("user_room_join", { roomID, userID });
        setJoinedRoom(true);
      }
    }
  }, [
    joinedRoom,
    roomID,
    roomState,
    roomState.usersInRoom,
    scrollViewRef,
    socket,
    userID,
    wordsAddedUsers,
    wordsDeletedUsers,
    wordsWritten,
  ]);

  useInterval(() => {
    setCurrentDate(new Date());
  }, 200);

  const computeBorderColor = useCallback(
    (username: string) => {
      if (
        wordsAddedUsers[username] &&
        currentDate.getTime() - wordsAddedUsers[username].getTime() <
          COLOR_DURATION
      ) {
        // return green with opacity depending on time elapsed
        // should fade from green to transparent over 2 seconds
        return "#53bf79";
        // return greenColorMap(
        //   (Date.now() - wordsAddedUsers[username].getTime()) / 2000
        // );
      } else if (
        wordsDeletedUsers[username] &&
        currentDate.getTime() - wordsDeletedUsers[username].getTime() <
          COLOR_DURATION
      ) {
        return "#ff6b6b";
        // return redColorMap(
        //   (Date.now() - wordsDeletedUsers[username].getTime()) / 2000
        // );
      } else {
        return null;
      }
    },
    [currentDate, wordsAddedUsers, wordsDeletedUsers]
  );

  useEffect(() => {
    if (data?.verifyRoom === false) {
      // not in a valid room, redirect to draft ID
      if (draftID) {
        navigate(`/draft/${draftID}`);
      } else {
        navigate("/");
      }
    }
  }, [data, draftID, navigate]);
  if (loading) return null;
  return (
    <div
      style={{
        borderColor: "lightgray",
        borderTopWidth: 1,
        flex: "0 1 auto",
      }}
    >
      <div style={{ padding: 10 }}>
        <div
          className="flex flex-row items-center"
          style={{ marginBottom: 12 }}
        >
          <p
            className="font-sans"
            style={{ color: "#456577", fontWeight: "bold", fontSize: 12 }}
          >
            Total room words: {wordsWritten}
          </p>
        </div>
        <div className="flex flex-row items-start justify-between">
          <div className="font-sans">
            <p style={{ fontSize: 14 }}>Writers in room</p>
          </div>
          <Button
            className="font-sans"
            size="sm"
            startContent={
              inviteLinkCopied ? <CopySuccess size={16} /> : <Copy size={16} />
            }
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/room/${roomID}`
              );
              setInviteLinkCopied(true);
            }}
          >
            {inviteLinkCopied ? "Copied!" : "Copy invite link"}
          </Button>
        </div>
        <div className="flex flex-row">
          {roomState.usersInRoom.map(
            ({ profilePhotoDownloadURL, username }) => (
              <div
                className="flex flex-col items-center"
                key={`room_user_${username}`}
                style={{ marginRight: 10 }}
              >
                <Avatar
                  src={profilePhotoDownloadURL ?? ""}
                  showFallback
                  isBordered={!!computeBorderColor(username)}
                  style={{
                    transitionProperty: "outline-color",
                    transitionDuration: "0.2s",
                    outlineColor: computeBorderColor(username) ?? undefined,
                  }}
                />
                <div className="font-sans">
                  <p style={{ fontSize: 12 }}>{username}</p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}
