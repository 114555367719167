import { gql, useQuery } from "@apollo/client";

import HomepageHeader from "../components/HomepageHeader";

import { setExportKey } from "../crypto/utils";
import RefreshedHome from "./RefreshedHome";
import { useMediaQuery } from "react-responsive";
import HomeFeed from "../components/HomeFeed";
import RoomsGrid from "../components/RoomsGrid";

const CHECK_LOGGED_IN = gql`
  query CheckLoggedIn {
    isLoggedIn
  }
`;

// const SEED_DATA = gql`
//   mutation SeedData {
//     seedData {
//       success
//     }
//   }
// `;

function Home() {
  const { data, loading } = useQuery(CHECK_LOGGED_IN, {
    fetchPolicy: "no-cache",
  });
  const isMobile = useMediaQuery({
    query: "(max-width: 980px)",
  });

  if (loading) {
    return <p>loading...</p>;
  }

  // not logged in
  if (!data || !data.isLoggedIn) {
    setExportKey("");
    return <RefreshedHome />;
  }
  return (
    <div>
      <div>
        <HomepageHeader />
      </div>
      <div className="flex flex-row w-full justify-center relative">
        {false ? <HomeFeed /> : <RoomsGrid />}
      </div>
    </div>
  );
}

export default Home;
