import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Spinner,
} from "@nextui-org/react";
import { ArrowRight, Add, Document, People } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { useUserInfo } from "../hooks/useUserInfo";
import { useMemo } from "react";
import { decryptSymmetricString } from "../crypto/utils";
import { DraftMetadata as gqlDraftMetadata } from "../gql/graphql";

const GET_DRAFTS_METADATA = gql`
  query GetDraftsMetadata {
    getDraftsMetadata(request: {}) {
      drafts {
        draftID
        encryptedTitle
        updatedAt
      }
      hasMore
    }
  }
`;

const CREATE_DRAFT = gql`
  mutation CreateDraft {
    createDraft
  }
`;


enum DropdownOptions {
  CREATE_NEW_DRAFT = "create-new-draft",
  CREATE_NEW_ROOM = "create-new-room",
}

type DraftMetadata = {
  draftID: string;
  title: string | null;
};

function DraftSelectorDropdown() {
  // NOTE: consider making this lazier if server is overloaded by queries
  const { data, loading } = useQuery(GET_DRAFTS_METADATA, {
    fetchPolicy: "no-cache",
  });
  const { decryptionKey } = useUserInfo();
  const [createDraft] = useMutation(CREATE_DRAFT);
  const navigate = useNavigate();
  const draftMetadataList = useMemo<DraftMetadata[]>(() => {
    if (!data || !decryptionKey) {
      return [];
    }
    return data.getDraftsMetadata.drafts.map((metadata: gqlDraftMetadata) => {
      return {
        draftID: metadata.draftID,
        title: decryptSymmetricString({
          encryptedPayload: metadata.encryptedTitle,
          secretKey: decryptionKey,
        }),
      };
    });
  }, [data, decryptionKey]);

  return (
    <Dropdown className="font-sans">
      <DropdownTrigger>
        <Button
          color="primary"
          className="font-sans"
          endContent={<ArrowRight />}
          size="sm"
        >
          GO WRITE
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        disabledKeys={["loading", "no-drafts", "loading-drafts"]}
        aria-labelledby="draft-selector-dropdown"
        style={{ width: 200 }}
        onAction={async (key) => {
          if (key === DropdownOptions.CREATE_NEW_DRAFT) {
            const response: { data?: { createDraft: string } } =
              await createDraft();
            if (!response.data) {
              console.error("Failed to create draft");
              return;
            }
            navigate(`/draft/${response.data.createDraft}`);
          } else if (key === DropdownOptions.CREATE_NEW_ROOM) {
            navigate("/create-room");
          }
        }}
      >
        {loading ? (
          <DropdownItem key="loading">
            <div className="flex flex-col items-center">
              <p className="font-sans mb-2">Loading your drafts...</p>
              <Spinner />
            </div>
          </DropdownItem>
        ) : (
          <DropdownSection showDivider>
            <DropdownItem
              key={DropdownOptions.CREATE_NEW_DRAFT}
              startContent={<Document size={16} />}
            >
              <p>New draft</p>
            </DropdownItem>
            <DropdownItem
              key={DropdownOptions.CREATE_NEW_ROOM}
              startContent={<People size={16} />}
            >
              <p>New room</p>
            </DropdownItem>
          </DropdownSection>
        )}
        <DropdownSection
          title="Recent drafts"
          showDivider={data?.getDraftsMetadata?.hasMore}
        >
          {loading ? (
            <DropdownItem key="loading-drafts">
              <div className="flex flex-col items-center">
                <p className="font-sans mb-2">Loading your drafts...</p>
                <Spinner />
              </div>
            </DropdownItem>
          ) : draftMetadataList.length > 0 ? (
            draftMetadataList.map((metadata) => {
              return (
                <DropdownItem
                  key={metadata.draftID}
                  onClick={() => {
                    navigate(`/draft/${metadata.draftID}`);
                  }}
                >
                  {metadata.title ?? "Untitled"}
                </DropdownItem>
              );
            })
          ) : (
            <DropdownItem key="no-drafts">No drafts found</DropdownItem>
          )}
        </DropdownSection>
        {data?.getDraftsMetadata?.hasMore && (
          <DropdownItem
            key="view-all"
            onClick={() => {
              navigate("/select-draft");
            }}
          >
            View all drafts
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

export default DraftSelectorDropdown;
