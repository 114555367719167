import ActivityItem from "../components/ActivityItem";
import ActivityProvider from "../providers/ActivityProvider";

function ActivityPage() {
  return (
    <div>
      <ActivityProvider>
        <ActivityItem />
      </ActivityProvider>
    </div>
  );
}

export default ActivityPage;
