import { useSpring, motion } from "framer-motion";
import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useInterval } from "../../hooks/useInterval";
import { Label, Line, LineChart, ResponsiveContainer, XAxis } from "recharts";
import { CartesianViewBox, ViewBox } from "recharts/types/util/types";
import { Avatar, Button, Input } from "@nextui-org/react";
import { Like1, Message2 } from "iconsax-react";
import { useMediaQuery } from "react-responsive";

//Spring animation parameters
const spring = {
  type: "spring",
  stiffness: 300,
  damping: 40,
};

const TYPE_SPEED = 100;

const WORDS_ADDED_TIMERS = [
  TYPE_SPEED, // 1
  TYPE_SPEED * 6, // 2
  TYPE_SPEED * 5, // 3
  TYPE_SPEED * 10, // 4
];

const WORDS_REMOVED_TIMERS = [
  TYPE_SPEED * 18, // 1
];

const SAMPLE_CHART_DATA = [
  {
    words: 0,
    time: 0,
  },
  {
    words: 3,
    time: (TYPE_SPEED * 12) / 1000,
  },
  {
    words: 2,
    time: (TYPE_SPEED * 18) / 1000,
  },
  {
    words: 3,
    time: (TYPE_SPEED * 22) / 1000,
  },
  {
    words: 3,
    time: (TYPE_SPEED * 28) / 1000,
  },
];

function TimerCard() {
  const [isFlipped, setIsFlipped] = useState(false);
  const [activityComplete, setActivityComplete] = useState(false);
  const [wordsAddedCount, setWordsAddedCount] = useState(0);
  const [wordsRemovedCount, setWordsRemovedCount] = useState(0);
  const [timer, setTimer] = useState(0);

  const isSmallDesktop = useMediaQuery({ query: "(max-width: 1000px)" });
  useInterval(
    () => {
      setTimer(timer + 1);
    },
    !activityComplete ? 1000 : null
  );
  useInterval(
    () => {
      setWordsAddedCount(wordsAddedCount + 1);
    },
    wordsAddedCount < WORDS_ADDED_TIMERS.length
      ? WORDS_ADDED_TIMERS[wordsAddedCount]
      : null
  );
  useInterval(
    () => {
      setWordsRemovedCount(wordsRemovedCount + 1);
    },
    wordsRemovedCount < WORDS_REMOVED_TIMERS.length
      ? WORDS_REMOVED_TIMERS[wordsRemovedCount]
      : null
  );
  useInterval(
    () => {
      if (!activityComplete) {
        setIsFlipped(true);
        setActivityComplete(true);
      }
    },
    !activityComplete ? TYPE_SPEED * 28 : null
  );

  const handleClick = () => {
    setIsFlipped((prevState) => !prevState);
  };

  const [rotateXaxis, setRotateXaxis] = useState(0);
  const [rotateYaxis, setRotateYaxis] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
    const element = ref.current;
    if (!element) {
      return;
    }
    const elementRect = element.getBoundingClientRect();
    const elementWidth = elementRect.width;
    const elementHeight = elementRect.height;
    const elementCenterX = elementWidth / 2;
    const elementCenterY = elementHeight / 2;
    const mouseX = event.clientY - elementRect.y - elementCenterY;
    const mouseY = event.clientX - elementRect.x - elementCenterX;
    const degreeX = (mouseX / elementWidth) * 20; //The number is the rotation factor
    const degreeY = (mouseY / elementHeight) * 20; //The number is the rotation factor
    setRotateXaxis(degreeX);
    setRotateYaxis(degreeY);
  };

  const handleMouseEnd = () => {
    setRotateXaxis(0);
    setRotateYaxis(0);
  };

  const dx = useSpring(0, spring);
  const dy = useSpring(0, spring);

  useEffect(() => {
    dx.set(-rotateXaxis);
    dy.set(rotateYaxis);
  }, [dx, dy, rotateXaxis, rotateYaxis]);

  return (
    <motion.div
      onClick={activityComplete ? handleClick : undefined}
      transition={spring}
      style={{
        perspective: "1200px",
        transformStyle: "preserve-3d",
        width: isSmallDesktop ? 300 : 500,
        height: 500,
        cursor: activityComplete ? "pointer" : "default",
      }}
    >
      <motion.div
        ref={ref}
        whileHover={{ scale: activityComplete ? 1.1 : 1 }} //Change the scale of zooming in when hovering
        onMouseMove={activityComplete ? handleMouseMove : undefined}
        onMouseLeave={activityComplete ? handleMouseEnd : undefined}
        transition={spring}
        style={{
          width: "100%",
          height: "100%",
          rotateX: dx,
          rotateY: dy,
        }}
      >
        <div
          style={{
            perspective: "1200px",
            transformStyle: "preserve-3d",
            width: "100%",
            height: "100%",
          }}
        >
          <motion.div
            animate={{ rotateY: isFlipped ? -180 : 0 }}
            transition={spring}
            style={{
              width: "100%",
              height: "100%",
              zIndex: isFlipped ? 0 : 1,
              backfaceVisibility: "hidden",
              position: "absolute",
            }}
          >
            <div
              className="flex flex-col items-center justify-center font-sans"
              style={{
                width: "100%",
                height: "100%",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
              }}
            >
              <div>
                <p style={{ fontSize: 40 }}>
                  00:{timer >= 10 ? timer : `0${timer}`}
                </p>
              </div>
              <div>
                <p>
                  <span style={{ color: "green" }}>+{wordsAddedCount}</span>
                  <span style={{ marginLeft: 8, marginRight: 8 }}>·</span>
                  <span style={{ color: "red" }}>-{wordsRemovedCount}</span>
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ rotateY: 180 }}
            animate={{ rotateY: isFlipped ? 0 : 180 }}
            transition={spring}
            style={{
              width: "100%",
              height: "100%",
              zIndex: isFlipped ? 1 : 0,
              backfaceVisibility: "hidden",
              position: "absolute",
              boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
            }}
          >
            <div
              className="flex flex-col items-start justify-center font-sans"
              style={{
                width: "100%",
                height: "100%",
                padding: 20,
              }}
            >
              <div>
                <p
                  style={{ fontSize: 20, marginBottom: 10, fontWeight: "bold" }}
                >
                  Writing the Draft Zero mission statement
                </p>
              </div>
              <div>
                <p style={{ fontSize: 16, marginBottom: 40 }}>
                  Excited to share it with the world!
                </p>
              </div>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart
                  width={400}
                  height={200}
                  data={SAMPLE_CHART_DATA}
                  style={{ cursor: "pointer" }}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <Line
                    type="monotone"
                    dataKey="words"
                    stroke="#8884d8"
                    dot={false}
                  />
                  <XAxis
                    dataKey="time"
                    min={0}
                    type="number"
                    // tick={<CustomizedAxisTick />}
                    label={
                      <Label
                        value="time"
                        position="insideRight"
                        offset={0}
                        content={(props: {
                          viewBox?: ViewBox;
                          value?: string | number;
                        }) => {
                          const cartesianViewBox =
                            props.viewBox as CartesianViewBox;
                          return (
                            <text
                              className="font-sans"
                              style={{ fontSize: "12px" }}
                              x={(cartesianViewBox.width as number) - 14}
                              y={(cartesianViewBox.y as number) - 5}
                              fill="gray"
                            >
                              {props.value}
                            </text>
                          );
                        }}
                      />
                    }
                  />
                </LineChart>
              </ResponsiveContainer>
              <div className="flex flex-row justify-between w-full items-center">
                <div className="flex flex-row">
                  <div style={{ marginRight: 8 }}>
                    <p>1 like</p>
                  </div>
                  <p>1 comment</p>
                </div>
                <div className="flex flex-row">
                  <div style={{ marginRight: 8 }}>
                    <Button isIconOnly={true} variant="light">
                      <Like1 size={20} />
                    </Button>
                  </div>
                  <div>
                    <Button isIconOnly={true} variant="light">
                      <Message2 size={20} />
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="flex flex-row font-sans"
                  style={{ marginTop: 12 }}
                >
                  <Avatar />
                  <div style={{ marginLeft: 8 }}>
                    <p>
                      <span style={{ fontWeight: "bold" }}>peter</span>
                      <span style={{ marginLeft: 8 }}>awesome writing!</span>
                    </p>

                    <p style={{ fontSize: 12, color: "gray" }}>Reply</p>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default TimerCard;
