import { Link, useNavigate } from "react-router-dom";
import UnauthenticatedHomePageHeader from "./UnauthenticatedHomePageHeader";
import { useUserInfo } from "../hooks/useUserInfo";
import SearchBarHeader from "./SearchBarHeader";
import { gql, useMutation } from "@apollo/client";
import { PureComponent, useContext, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Textarea,
} from "@nextui-org/react";
import { Like1, More } from "iconsax-react";
import { convertSecondsToString } from "../utils/string";
import { Label, Line, LineChart, ResponsiveContainer, XAxis } from "recharts";
import { v4 } from "uuid";
import {
  ActivityActionType,
  ActivityContext,
  ActivityContextType,
} from "../providers/ActivityProvider";
import ActivityCommentThread from "./ActivityCommentThread";
import { useMediaQuery } from "react-responsive";
import { CartesianViewBox, ViewBox } from "recharts/types/util/types";

const MODIFY_FINISHED_ACTIVITY = gql`
  mutation modifyFinishedActivity(
    $activityID: String!
    $title: String
    $description: String
    $checksum: String!
  ) {
    modifyFinishedActivity(
      request: {
        activityID: $activityID
        title: $title
        description: $description
        checksum: $checksum
      }
    ) {
      checksum
    }
  }
`;

const LIKE_ACTIVITY = gql`
  mutation likeActivity($activityID: String!, $activityLikeID: String!) {
    likeActivity(
      request: { activityID: $activityID, activityLikeID: $activityLikeID }
    ) {
      success
    }
  }
`;

const UNLIKE_ACTIVITY = gql`
  mutation unlikeActivity($activityID: String!) {
    unlikeActivity(request: { activityID: $activityID }) {
      success
    }
  }
`;

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props as {
      x: number;
      y: number;
      stroke: string;
      payload: { value: number };
    };

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={6}
          y={0}
          dy={16}
          textAnchor="end"
          className="font-sans"
          fontSize={12}
          fill="#666"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

function ActivityItem() {
  const { userID } = useUserInfo();
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { dispatch, activity } =
    useContext<ActivityContextType>(ActivityContext);
  const { username } = useUserInfo();
  const navigate = useNavigate();
  const [updateActivity] = useMutation(MODIFY_FINISHED_ACTIVITY);
  const [likeActivity] = useMutation(LIKE_ACTIVITY);
  const [unlikeActivity] = useMutation(UNLIKE_ACTIVITY);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(activity?.title ?? "");
  const [description, setDescription] = useState(activity?.description ?? "");
  const isSelf = useMemo(
    () => username === activity?.username,
    [activity?.username, username]
  );
  const isLoggedIn = useMemo(() => !!userID, [userID]);
  const userActivityLikeID = activity?.activityLikes?.find(
    (like) => like.username === username
  )?.activityLikeID;
  if (!activity) {
    return null;
  }
  return (
    <div>
      {userID ? <SearchBarHeader /> : <UnauthenticatedHomePageHeader />}
      <div
        className="flex w-full h-full flex-col items-center"
        style={{
          padding: 20,
          marginTop: 20,
        }}
      >
        <div
          className="h-full flex"
          style={{
            width: "100%",
            maxWidth: 800,
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <div style={{ flex: 2 }}>
            <div className="flex flex-row justify-between w-full">
              <div
                className="flex flex-row cursor-pointer"
                onClick={() => {
                  navigate(`/writer/${activity.username}`);
                }}
              >
                <Avatar src={activity.imageDownloadURL || ""} showFallback />
                <div className="flex flex-col ml-2">
                  <p className="font-sans font-semibold">{activity.username}</p>
                  <p className="font-sans" style={{ fontSize: 12 }}>
                    {new Date(activity.createdAt).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}{" "}
                    at{" "}
                    {new Date(activity.createdAt).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </p>
                </div>
              </div>
              {isSelf && !isEditing && (
                <div>
                  <Dropdown>
                    <DropdownTrigger>
                      <Button isIconOnly variant="light" size="sm">
                        <More size={20} />
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      >
                        Edit
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              )}
              {isSelf && isEditing && (
                <div className="flex flex-row">
                  <Button
                    size="sm"
                    onClick={() => {
                      if (!activity.title) {
                        return;
                      }
                      setTitle(activity.title);
                      setDescription(activity.description ?? "");
                      setIsEditing(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    onClick={async () => {
                      if (!title) {
                        alert("Title is required");
                        return;
                      }
                      await updateActivity({
                        variables: {
                          activityID: activity.activityID,
                          title,
                          description,
                          checksum: activity.checksum,
                        },
                      });
                      setIsEditing(false);
                    }}
                    color="primary"
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
            <div className="mt-2 mb-2" style={{ maxWidth: 400 }}>
              {!isEditing && (
                <Link to={`/session/${activity.activityID}`}>
                  <div>
                    <p
                      className="font-sans font-semibold"
                      style={{ fontSize: 20 }}
                    >
                      {activity.title}
                    </p>
                  </div>
                </Link>
              )}
              {isEditing && (
                <div>
                  <Input
                    value={title}
                    onValueChange={(value) => setTitle(value)}
                    placeholder="Title"
                  />
                </div>
              )}
              {!isEditing && activity.description && (
                <div>
                  <p className="font-sans">{activity.description}</p>
                </div>
              )}
              {isEditing && (
                <div>
                  <Textarea
                    value={description ?? ""}
                    onValueChange={(value) => setDescription(value)}
                    placeholder="Description"
                  />
                </div>
              )}
            </div>
            <div className="flex flex-row">
              <div style={{ marginRight: 10, flex: 1 }}>
                <p className="font-sans" style={{ fontSize: 12 }}>
                  Additions
                </p>
                <p className="font-sans">{activity.wordsAdded} words</p>
              </div>
              <div style={{ marginRight: 10, flex: 1 }}>
                <p className="font-sans" style={{ fontSize: 12 }}>
                  Deletions
                </p>
                <p className="font-sans">{activity.wordsDeleted} words</p>
              </div>
              <div style={{ flex: 1 }}>
                <p className="font-sans" style={{ fontSize: 12 }}>
                  Time
                </p>
                <p className="font-sans">
                  {convertSecondsToString(activity.secondsElapsed ?? 0)}
                </p>
              </div>
            </div>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart
                width={400}
                height={200}
                data={activity.secondsElapsedArray.map(function (x, i) {
                  return {
                    time: x,
                    words: activity.wordDifferentialArray[i],
                  };
                })}
                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              >
                <Line
                  type="monotone"
                  dataKey="words"
                  stroke="#8884d8"
                  dot={false}
                />
                <XAxis
                  dataKey="time"
                  min={0}
                  type="number"
                  tick={<CustomizedAxisTick />}
                  label={
                    <Label
                      value="minutes"
                      position="insideRight"
                      offset={0}
                      content={(props: {
                        viewBox?: ViewBox;
                        value?: string | number;
                      }) => {
                        const cartesianViewBox =
                          props.viewBox as CartesianViewBox;
                        return (
                          <text
                            className="font-sans"
                            style={{ fontSize: "12px" }}
                            x={(cartesianViewBox.width as number) - 30}
                            y={(cartesianViewBox.y as number) - 5}
                            fill="gray"
                          >
                            {props.value}
                          </text>
                        );
                      }}
                    />
                  }
                />
              </LineChart>
            </ResponsiveContainer>
            <div className="mt-2 flex flex-col items-start">
              <div className="flex flex-row items-center">
                <div
                  className="font-sans flex flex-row items-center"
                  style={{ marginLeft: -8 }}
                >
                  <Button
                    startContent={
                      <Like1
                        variant={userActivityLikeID ? "Bold" : "Linear"}
                        color={userActivityLikeID ? "#7194A8" : "black"}
                      />
                    }
                    isIconOnly
                    variant="light"
                    onClick={async () => {
                      if (!isLoggedIn) {
                        navigate("/login");
                        return;
                      }
                      if (userActivityLikeID) {
                        dispatch({
                          type: ActivityActionType.RemoveActivityLike,
                          payload: {
                            activityID: activity.activityID,
                            activityLikeID: userActivityLikeID,
                          },
                        });
                        await unlikeActivity({
                          variables: {
                            activityID: activity.activityID,
                            activityLikeID: userActivityLikeID,
                          },
                        });
                        return;
                      } else {
                        const activityLikeID = v4();
                        dispatch({
                          type: ActivityActionType.AddActivityLike,
                          payload: {
                            activityID: activity.activityID,
                            username,
                            activityLikeID,
                          },
                        });
                        await likeActivity({
                          variables: {
                            activityLikeID,
                            activityID: activity.activityID,
                          },
                        });
                      }
                    }}
                  />
                </div>
                <p className="font-sans" style={{ fontSize: 12 }}>
                  {activity.activityLikes?.length &&
                  activity.activityLikes?.length > 0
                    ? `${activity.activityLikes?.length} like${
                        activity.activityLikes?.length !== 1 ? "s" : ""
                      }`
                    : "Like"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1" style={{ marginLeft: 24 }}>
            <ActivityCommentThread
              comments={activity.comments}
              activityID={activity.activityID}
              dispatch={dispatch}
              forceStartCommentIndex={0}
              hideCollapseComments={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityItem;
