import ActivityFeedItem from "./ActivityFeedItem";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useMediaQuery } from "react-responsive";
import { FeedContext, FeedContextType } from "../providers/FeedProvider";

function ActivityFeed({
  maxHeight,
  prefixItem,
  leftAligned = false,
}: {
  children?: React.ReactNode;
  maxHeight: string;
  prefixItem?: React.ReactNode;
  leftAligned?: boolean;
}) {
  const { feed, fetchMore } = useContext<FeedContextType>(FeedContext);
  const isMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });
  const parentRef = useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: feed.activities?.length,
    getScrollElement: () => {
      return parentRef.current;
    },
    estimateSize: () => 472,
  });
  const virtualItems = rowVirtualizer.getVirtualItems();
  const lastVirtualItemsIndex = useMemo(() => {
    return virtualItems[virtualItems.length - 1]?.index;
  }, [virtualItems]);
  useEffect(() => {
    if (lastVirtualItemsIndex) {
      if (
        feed &&
        lastVirtualItemsIndex === feed.activities.length - 1 &&
        feed.hasMore
      ) {
        fetchMore();
      }
    }
  }, [feed, feed.activities?.length, fetchMore, lastVirtualItemsIndex]);

  return (
    <div
      className="flex flex-col"
      style={{
        maxHeight,
        height: maxHeight,
        minHeight: 500,
        overflow: "auto",
        width: "100%",
        alignItems: leftAligned ? "flex-start" : "center",
      }}
      ref={parentRef}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          minHeight: `${rowVirtualizer.getTotalSize()}px`,
          width: isMobile ? "100%" : 500,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${
              rowVirtualizer.getVirtualItems()[0]?.start ?? 0
            }px)`,
          }}
        >
          {prefixItem}
          {rowVirtualizer.getVirtualItems().map((virtualItem) => (
            <div
              key={feed.activities[virtualItem.index].activityID}
              data-index={virtualItem.index}
              ref={rowVirtualizer.measureElement}
            >
              <ActivityFeedItem
                activityMetadata={feed.activities[virtualItem.index]}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ActivityFeed;
