import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { gql, useLazyQuery } from "@apollo/client";
import { setUserInfo } from "../redux/userStore";
import { useEffect } from "react";
import { decryptSymmetric, getExportKey } from "../crypto/utils";
import { fromByteArray } from "base64-js";
import { UserInfo } from "../gql/graphql";

const GET_USER_INFO = gql`
  query GetUserInfo {
    getUserInfo {
      encryptedDecryptionKey
      userID
      username
      profilePhotoURL
      firstName
      lastName
      bio
      link
    }
  }
`;

export function useUserInfo() {
  let decryptionKey = useSelector(
    (state: RootState) => state.user.decryptionKey
  );
  const userID = useSelector((state: RootState) => state.user.userID);
  const username = useSelector((state: RootState) => state.user.username);
  const profilePhotoDownloadURL = useSelector(
    (state: RootState) => state.user.profilePhotoDownloadURL
  );
  const firstName = useSelector((state: RootState) => state.user.firstName);
  const lastName = useSelector((state: RootState) => state.user.lastName);
  const bio = useSelector((state: RootState) => state.user.bio);
  const link = useSelector((state: RootState) => state.user.link);

  const dispatch = useDispatch();
  const [getEncryptedDecryptionKey, { loading }] = useLazyQuery(GET_USER_INFO, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    async function retrieveAndSetDecryptionKey() {
      const result: { data: { getUserInfo: UserInfo } } =
        await getEncryptedDecryptionKey();
      const exportKey = getExportKey();
      if (!exportKey || !result.data) {
        return;
      }
      const decryptionKey = decryptSymmetric({
        secretKey: exportKey,
        encryptedPayload: result.data.getUserInfo.encryptedDecryptionKey,
      });
      dispatch(
        setUserInfo({
          decryptionKey: fromByteArray(decryptionKey),
          ...result.data.getUserInfo,
          profilePhotoDownloadURL: result.data.getUserInfo.profilePhotoURL,
        })
      );
    }
    if (!decryptionKey && getExportKey()) {
      retrieveAndSetDecryptionKey();
    }
  }, [decryptionKey, dispatch, getEncryptedDecryptionKey]);

  return {
    decryptionKey,
    userID,
    username,
    profilePhotoDownloadURL,
    firstName,
    lastName,
    bio,
    loading,
    link,
  };
}
