import { Suspense, useEffect, useMemo, useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { FollowerUserMetadata, FollowingStatus } from "../gql/graphql";
import { useUserInfo } from "../hooks/useUserInfo";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, CircularProgress, Switch } from "@nextui-org/react";
import RequestButton from "../components/RequestButton";
import ActivityGrid from "../components/ActivityGrid";
import ActivityFeed from "../components/ActivityFeed";
import { CloseCircle, InfoCircle } from "iconsax-react";
import UnauthenticatedHomePageHeader from "../components/UnauthenticatedHomePageHeader";
import { useMediaQuery } from "react-responsive";
import FeedProvider from "../providers/FeedProvider";
import SearchBarHeader from "../components/SearchBarHeader";
import Sundial from "../components/Sundial";
import ProfileActivityFeed from "../components/ProfileActivityFeed";

const GET_OTHER_USER_INFO = gql`
  query GetOtherUserInfo($username: String!) {
    getOtherUserInfo(request: { username: $username }) {
      profilePhotoURL
      followingStatus
      followerStatus
      firstName
      lastName
      bio
      link
      private
      followerCount
      followingCount
      followers {
        username
      }
      following {
        username
      }
      percentWordsThisWeek
      percentWordsAllTime
      sundialScore
    }
  }
`;

const GET_SELF_PROFILE_INFO = gql`
  query GetSelfProfileInfo {
    getSelfProfileInfo {
      followers {
        username
      }
      following {
        username
      }
      percentWordsThisWeek
      percentWordsAllTime
      sundialScore
    }
  }
`;

function Profile() {
  const { username } = useParams();
  const isMobile = useMediaQuery({
    query: "(max-width: 1060px)",
  });
  const [getOtherUserInfo, { data: otherUserData }] = useLazyQuery(
    GET_OTHER_USER_INFO,
    {
      fetchPolicy: "no-cache",
      variables: { username },
    }
  );
  const { userID, loading } = useUserInfo();

  const [getSelfProfileInfo] = useLazyQuery(GET_SELF_PROFILE_INFO, {
    fetchPolicy: "no-cache",
  });
  const [showWordLog, setShowWordLog] = useState(false);
  const [showFollowerPane, setShowFollowerPane] = useState(false);
  const [showingFollowers, setShowingFollowers] = useState(false);
  const [otherUserInfoState, setOtherUserInfoState] = useState<{
    followingStatus: FollowingStatus | null;
    followerStatus: FollowingStatus | null;
    private: boolean;
    followerCount: number;
    followingCount: number;
    followers: string[];
    following: string[];
    link?: string;
    percentWordsThisWeek: number;
    percentWordsAllTime: number;
    sundialScore: number;
  }>({
    followerStatus: null,
    followingStatus: null,
    private: false,
    followerCount: 0,
    followingCount: 0,
    followers: [],
    following: [],
    percentWordsThisWeek: 0,
    percentWordsAllTime: 0,
    sundialScore: 0,
  });
  const [selfInfoState, setSelfInfoState] = useState<{
    followers: string[];
    following: string[];
    percentWordsThisWeek: number;
    percentWordsAllTime: number;
    sundialScore: number;
  }>({
    followers: [],
    following: [],
    percentWordsThisWeek: 0,
    percentWordsAllTime: 0,
    sundialScore: 0,
  });
  const {
    profilePhotoDownloadURL,
    username: reduxUsername,
    firstName,
    lastName,
    bio,
    link,
  } = useUserInfo();
  const navigate = useNavigate();
  const [showTimeTooltip, setShowTimeTooltip] = useState(false);
  const isSelf = useMemo(() => {
    return username === reduxUsername;
  }, [reduxUsername, username]);
  const shouldShowFollowers = useMemo<boolean>(
    () =>
      otherUserInfoState?.followingStatus === FollowingStatus.Accepted ||
      !otherUserInfoState.private ||
      isSelf,
    [isSelf, otherUserInfoState?.followingStatus, otherUserInfoState.private]
  );

  useEffect(() => {
    if (!isSelf) {
      getOtherUserInfo({
        onCompleted: (data) => {
          setOtherUserInfoState({
            followingStatus: data.getOtherUserInfo.followingStatus,
            followerStatus: data.getOtherUserInfo.followerStatus,
            private: data.getOtherUserInfo.private,
            followerCount: data.getOtherUserInfo.followerCount,
            followingCount: data.getOtherUserInfo.followingCount,
            followers:
              data.getOtherUserInfo.followers?.map(
                (f: FollowerUserMetadata) => f.username
              ) ?? [],
            following:
              data.getOtherUserInfo.following?.map(
                (f: FollowerUserMetadata) => f.username
              ) ?? [],
            link: data.getOtherUserInfo.link,
            percentWordsThisWeek: data.getOtherUserInfo.percentWordsThisWeek,
            percentWordsAllTime: data.getOtherUserInfo.percentWordsAllTime,
            sundialScore: data.getOtherUserInfo.sundialScore,
          });
        },
      });
    } else {
      getSelfProfileInfo({
        onCompleted: (data) => {
          setSelfInfoState({
            followers: data.getSelfProfileInfo.followers.map(
              (f: FollowerUserMetadata) => f.username
            ),
            following: data.getSelfProfileInfo.following.map(
              (f: FollowerUserMetadata) => f.username
            ),
            percentWordsThisWeek: data.getSelfProfileInfo.percentWordsThisWeek,
            percentWordsAllTime: data.getSelfProfileInfo.percentWordsAllTime,
            sundialScore: data.getSelfProfileInfo.sundialScore,
          });
        },
      });
    }
  }, [getOtherUserInfo, getSelfProfileInfo, isSelf]);

  function MobileHeader() {
    return (
      <div
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 20,
        }}
      >
        <div className="flex flex-row items-center">
          <Avatar
            src={
              isSelf
                ? profilePhotoDownloadURL ?? ""
                : otherUserData?.getOtherUserInfo.profilePhotoURL ?? ""
            }
            showFallback
            style={{ width: 100, height: 100, marginRight: 20 }}
          />
          <div>
            {isSelf && firstName && (
              <div className="mt-2">
                <p
                  className="font-sans"
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  {firstName} {lastName}
                </p>
              </div>
            )}
            {!isSelf && otherUserData?.getOtherUserInfo.firstName && (
              <div className="mt-2">
                <p
                  className="font-sans"
                  style={{ fontSize: 20, fontWeight: "bold" }}
                >
                  {otherUserData?.getOtherUserInfo.firstName}{" "}
                  {otherUserData?.getOtherUserInfo.lastName}
                </p>
              </div>
            )}
            <div className="mt-2">
              <p className="font-sans" style={{ fontSize: 20 }}>
                {username}
              </p>
            </div>
            {!isSelf && (
              <RequestButton
                setOtherUserInfoState={setOtherUserInfoState}
                otherUserInfoState={otherUserInfoState}
              />
            )}

            <div className="w-full" style={{ marginTop: 16 }}>
              <div className="flex flex-row w-full">
                <div
                  className="font-sans"
                  style={{
                    cursor: shouldShowFollowers ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (shouldShowFollowers) {
                      setShowFollowerPane(true);
                      setShowingFollowers(false);
                    }
                  }}
                >
                  <p style={{ fontSize: 12 }}>
                    <span style={{ fontWeight: "bold" }}>
                      {isSelf
                        ? selfInfoState.following.length
                        : otherUserInfoState.followingCount}
                    </span>{" "}
                    following
                  </p>
                </div>
                <div style={{ marginLeft: 6, marginRight: 6, fontSize: 12 }}>
                  ·
                </div>
                <div
                  className="font-sans"
                  style={{
                    cursor: shouldShowFollowers ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (shouldShowFollowers) {
                      setShowFollowerPane(true);
                      setShowingFollowers(true);
                    }
                  }}
                >
                  <p style={{ fontSize: 12 }}>
                    <span style={{ fontWeight: "bold" }}>
                      {isSelf
                        ? selfInfoState.followers.length
                        : otherUserInfoState.followerCount}{" "}
                    </span>
                    follower{otherUserInfoState.followerCount !== 1 ? "s" : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          <p className="font-sans font-semibold">Activity grid</p>{" "}
        </div>
        <div
          className="flex flex-row items-center"
          style={{ marginBottom: 10 }}
        >
          <div className="flex flex-row">
            <p className="font-sans" style={{ fontSize: 12 }}>
              Time
            </p>
            <div
              style={{ position: "relative", marginLeft: 4 }}
              onMouseEnter={() => {
                setShowTimeTooltip(true);
              }}
              onMouseLeave={() => {
                setShowTimeTooltip(false);
              }}
              onClick={() => setShowTimeTooltip(!showTimeTooltip)}
              onBlur={() => setShowTimeTooltip(false)}
            >
              <InfoCircle style={{ width: 16, height: 16, color: "gray" }} />
              {showTimeTooltip && (
                <div
                  className="font-sans"
                  style={{
                    position: "absolute",
                    width: 100,
                    backgroundColor: "white",
                    fontSize: 10,
                    padding: 5,
                    zIndex: 1,
                    left: -40,
                    top: 20,
                  }}
                >
                  Time is measured from your recorded sessions.
                </div>
              )}
            </div>
          </div>
          <div style={{ marginLeft: 8 }}>
            <Switch
              size="sm"
              isSelected={showWordLog}
              onValueChange={setShowWordLog}
              classNames={{
                wrapper: [
                  "group-data-[selected=true]:bg-secondary",
                  "bg-primary",
                ],
              }}
            />
          </div>
          <p className="font-sans" style={{ fontSize: 12 }}>
            Words
          </p>
        </div>
        <ActivityGrid showWordLog={showWordLog} />
        <div style={{ marginTop: 30 }}>
          <p className="font-sans font-semibold text-lg">Recent sessions</p>
        </div>
      </div>
    );
  }

  if (loading) {
    return null;
  }

  return (
    <div>
      {userID ? <SearchBarHeader /> : <UnauthenticatedHomePageHeader />}
      {isMobile && (
        <div className="flex flex-col w-full">
          <div
            className="flex flex-col items-start"
            style={{
              width: "100%",
            }}
          >
            {(isSelf ||
              !otherUserInfoState.private ||
              otherUserInfoState.followingStatus ===
                FollowingStatus.Accepted) && (
              <Suspense fallback={<p>loading...</p>}>
                <FeedProvider>
                  <ActivityFeed
                    prefixItem={<MobileHeader />}
                    leftAligned={true}
                    maxHeight="calc(100dvh - 60px)"
                  />
                </FeedProvider>
              </Suspense>
            )}
            {!isSelf &&
              otherUserInfoState.private &&
              otherUserInfoState.followingStatus !==
                FollowingStatus.Accepted && (
                <>
                  <MobileHeader />
                  <div style={{ padding: 20 }}>
                    <p className="font-sans">
                      This user's profile is private. Follow them to see their
                      writing sessions.
                    </p>
                  </div>
                </>
              )}
          </div>
        </div>
      )}
      {!isMobile && (
        <div className="flex flex-row w-full justify-center relative">
          {/* Profile pane */}
          <div
            className="flex flex-row justify-end"
            style={{ width: "calc(50vw - 250px)" }}
          >
            <div style={{ margin: 20, marginRight: 50, width: 200 }}>
              <Avatar
                src={
                  isSelf
                    ? profilePhotoDownloadURL ?? ""
                    : otherUserData?.getOtherUserInfo.profilePhotoURL ?? ""
                }
                showFallback
                style={{ width: 200, height: 200 }}
              />
              {isSelf && firstName && (
                <div className="mt-2">
                  <p
                    className="font-sans"
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    {firstName} {lastName}
                  </p>
                </div>
              )}
              {!isSelf && otherUserData?.getOtherUserInfo.firstName && (
                <div className="mt-2">
                  <p
                    className="font-sans"
                    style={{ fontSize: 20, fontWeight: "bold" }}
                  >
                    {otherUserData?.getOtherUserInfo.firstName}{" "}
                    {otherUserData?.getOtherUserInfo.lastName}
                  </p>
                </div>
              )}
              <div className="mt-2">
                <p className="font-sans" style={{ fontSize: 20 }}>
                  {username}
                </p>
              </div>
              {isSelf && link && (
                <div className="mt-2">
                  <a href={link}>
                    <p
                      className="font-sans"
                      style={{
                        fontSize: 12,
                        textDecoration: "underline",
                        color: "#7194A8",
                      }}
                    >
                      {link}
                    </p>
                  </a>
                </div>
              )}
              {!isSelf && otherUserData?.getOtherUserInfo.link && (
                <div className="mt-2">
                  <a href={otherUserData?.getOtherUserInfo.link}>
                    <p
                      className="font-sans"
                      style={{
                        fontSize: 12,
                        textDecoration: "underline",
                        color: "#7194A8",
                      }}
                    >
                      {otherUserData?.getOtherUserInfo.link}
                    </p>
                  </a>
                </div>
              )}
              {isSelf && bio && (
                <div className="mt-2">
                  <p className="font-sans" style={{ fontSize: 14 }}>
                    {bio}
                  </p>
                </div>
              )}
              {!isSelf && otherUserData?.getOtherUserInfo.bio && (
                <div className="mt-2">
                  <p className="font-sans" style={{ fontSize: 16 }}>
                    {otherUserData?.getOtherUserInfo.bio}
                  </p>
                </div>
              )}
              <div style={{ marginTop: 16 }}>
                {isSelf && (
                  <div>
                    <Button
                      className="font-sans w-full h-8"
                      onClick={() => navigate("/settings")}
                    >
                      Settings
                    </Button>
                  </div>
                )}
                {!isSelf && (
                  <RequestButton
                    setOtherUserInfoState={setOtherUserInfoState}
                    otherUserInfoState={otherUserInfoState}
                  />
                )}
              </div>
              <div className="w-full" style={{ marginTop: 16 }}>
                <div className="flex flex-row w-full">
                  <div
                    className="font-sans"
                    style={{
                      cursor: shouldShowFollowers ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (shouldShowFollowers) {
                        setShowFollowerPane(true);
                        setShowingFollowers(false);
                      }
                    }}
                  >
                    <p style={{ fontSize: 12 }}>
                      <span style={{ fontWeight: "bold" }}>
                        {isSelf
                          ? selfInfoState.following.length
                          : otherUserInfoState.followingCount}
                      </span>{" "}
                      following
                    </p>
                  </div>
                  <div style={{ marginLeft: 6, marginRight: 6 }}>
                    <p style={{ fontSize: 12 }}>·</p>
                  </div>
                  <div
                    className="font-sans"
                    style={{
                      cursor: shouldShowFollowers ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (shouldShowFollowers) {
                        setShowFollowerPane(true);
                        setShowingFollowers(true);
                      }
                    }}
                  >
                    <p style={{ fontSize: 12 }}>
                      <span style={{ fontWeight: "bold" }}>
                        {isSelf
                          ? selfInfoState.followers.length
                          : otherUserInfoState.followerCount}
                      </span>{" "}
                      follower
                      {otherUserInfoState.followerCount !== 1 ? "s" : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="flex flex-row w-full justify-between font-sans"
                style={{ marginTop: 16 }}
              >
                <div className="flex-1">
                  <Sundial
                    sundialScore={
                      isSelf
                        ? selfInfoState.sundialScore
                        : otherUserData?.getOtherUserInfo.sundialScore ?? 0
                    }
                  />
                </div>
                <div className="flex flex-1 flex-col items-center">
                  <CircularProgress
                    value={
                      (isSelf
                        ? selfInfoState.percentWordsAllTime
                        : otherUserInfoState.percentWordsAllTime) * 100
                    }
                    size="lg"
                    showValueLabel
                  />
                  <p style={{ fontSize: 12, textAlign: "center" }}>
                    of all words on draft zero
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Activity feed pane */}
          <div className="flex flex-col items-start font-sans">
            {showFollowerPane && (
              <div className="flex flex-col w-full" style={{ padding: 20 }}>
                <div className="flex flex-row justify-between">
                  <p>{showingFollowers ? "Followers" : "Following"}</p>
                  <Button
                    isIconOnly
                    onClick={() => setShowFollowerPane(false)}
                    variant="light"
                  >
                    <CloseCircle />
                  </Button>
                </div>
                <div className="flex flex-col w-full">
                  {showingFollowers &&
                    (isSelf ? selfInfoState : otherUserInfoState).followers.map(
                      (f) => (
                        <div
                          className="flex flex-row w-full cursor-pointer"
                          onClick={() => {
                            setShowFollowerPane(false);
                            navigate(`/writer/${f}`);
                          }}
                        >
                          <p>{f}</p>
                        </div>
                      )
                    )}
                  {!showingFollowers &&
                    (isSelf ? selfInfoState : otherUserInfoState).following.map(
                      (f) => (
                        <div
                          className="flex flex-row w-full cursor-pointer"
                          onClick={() => {
                            setShowFollowerPane(false);
                            navigate(`/writer/${f}`);
                          }}
                        >
                          <p>{f}</p>
                        </div>
                      )
                    )}
                </div>
              </div>
            )}
            {!showFollowerPane && (
              <>
                <div
                  className="flex flex-col items-start"
                  style={{ width: "calc(50vw + 250px)" }}
                >
                  {(isSelf ||
                    !otherUserInfoState.private ||
                    otherUserInfoState.followingStatus ===
                      FollowingStatus.Accepted) && (
                    <Suspense fallback={<p>loading...</p>}>
                      <FeedProvider>
                        <ProfileActivityFeed
                          prefixItem={
                            <>
                              <div style={{ marginTop: 30 }}>
                                <p className="font-sans font-semibold text-lg">
                                  Recent sessions
                                </p>
                              </div>
                            </>
                          }
                          leftAligned={true}
                          maxHeight="calc(100dvh - 60px)"
                        />
                      </FeedProvider>
                    </Suspense>
                  )}
                  {!isSelf &&
                    otherUserInfoState.private &&
                    otherUserInfoState.followingStatus !==
                      FollowingStatus.Accepted && (
                      <p className="font-sans">
                        This user's profile is private. Follow them to see their
                        writing sessions.
                      </p>
                    )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
