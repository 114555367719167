import {
  Suspense,
  createContext,
  startTransition,
  useEffect,
  useReducer,
} from "react";
import { ActivityFeedMetadata } from "../gql/graphql";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { CommentActionType } from "../components/ActivityCommentThread";
import { Spinner } from "@nextui-org/react";

export enum ActivityActionType {
  Initialize,
  Update,
  AddActivityLike,
  RemoveActivityLike,
}

export type ActivityContextType = {
  activity: ActivityFeedMetadata | null;
  dispatch: React.Dispatch<{
    type: ActivityActionType | CommentActionType;
    payload: any;
  }>;
};

function activityReducer(
  activity: ActivityFeedMetadata | null,
  action: { type: ActivityActionType | CommentActionType; payload: any }
) {
  switch (action.type) {
    case ActivityActionType.Initialize:
      return action.payload;
    case ActivityActionType.Update:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        title:
          action.payload.title === undefined
            ? activity.title
            : action.payload.title,
        description:
          action.payload.description === undefined
            ? activity.description
            : action.payload.description,
      };
    case ActivityActionType.AddActivityLike:
      if (activity === null) {
        return null;
      }
      const newLikes = activity.activityLikes;
      if (!newLikes.find((like) => like.username === action.payload.username)) {
        newLikes.push({
          username: action.payload.username,
          activityLikeID: action.payload.activityLikeID,
        });
      }
      return {
        ...activity,
        activityLikes: newLikes,
      };
    case ActivityActionType.RemoveActivityLike:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        activityLikes: activity.activityLikes.filter(
          (like) => like.activityLikeID !== action.payload.activityLikeID
        ),
      };
    case CommentActionType.AddActivityComment:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        comments: [
          ...activity.comments,
          {
            commentID: action.payload.commentID,
            username: action.payload.username,
            comment: action.payload.comment,
            createdAt: new Date(),
            profileImageURL: action.payload.profileImageURL,
            likes: [],
            replies: [],
          },
        ],
      };

    case CommentActionType.RemoveActivityComment:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        comments: activity.comments.filter(
          (comment) => comment.commentID !== action.payload.commentID
        ),
      };

    case CommentActionType.UpdateActivityComment:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        comments: activity.comments.map((comment) => {
          if (comment.commentID === action.payload.commentID) {
            return {
              ...comment,
              comment: action.payload.comment,
            };
          }
          return comment;
        }),
      };

    case CommentActionType.AddActivityCommentLike:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        comments: activity.comments.map((comment) => {
          if (comment.commentID === action.payload.commentID) {
            return {
              ...comment,
              likes: [
                {
                  username: action.payload.username,
                  commentLikeID: action.payload.commentLikeID,
                },
                ...comment.likes,
              ],
            };
          }
          return comment;
        }),
      };

    case CommentActionType.RemoveActivityCommentLike:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        comments: activity.comments.map((comment) => {
          if (comment.commentID === action.payload.commentID) {
            return {
              ...comment,
              likes: comment.likes.filter(
                (like) => like.commentLikeID !== action.payload.commentLikeID
              ),
            };
          }
          return comment;
        }),
      };

    case CommentActionType.ReplyToComment:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        comments: activity.comments.map((comment) => {
          if (comment.commentID === action.payload.commentID) {
            return {
              ...comment,
              replies: [
                ...(comment.replies ?? []),
                {
                  commentID: action.payload.replyID,
                  username: action.payload.username,
                  comment: action.payload.comment,
                  createdAt: new Date(),
                  profileImageURL: action.payload.profileImageURL,
                  likes: [],
                },
              ],
            };
          }
          return comment;
        }),
      };
    case CommentActionType.DeleteReplyToComment:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        comments: activity.comments.map((comment) => {
          if (comment.commentID === action.payload.commentID) {
            return {
              ...comment,
              replies: comment.replies?.filter(
                (reply) => reply.commentID !== action.payload.replyID
              ),
            };
          }
          return comment;
        }),
      };
    case CommentActionType.LikeReplyToComment:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        comments: activity.comments.map((comment) => {
          if (comment.commentID === action.payload.commentID) {
            return {
              ...comment,
              replies: comment.replies?.map((reply) => {
                if (reply.commentID === action.payload.replyID) {
                  return {
                    ...reply,
                    likes: [
                      {
                        username: action.payload.username,
                        replyLikeID: action.payload.replyLikeID,
                      },
                      ...reply.likes,
                    ],
                  };
                }
                return reply;
              }),
            };
          }
          return comment;
        }),
      };

    case CommentActionType.UnlikeReplyToComment:
      if (activity === null) {
        return null;
      }
      return {
        ...activity,
        comments: activity.comments.map((comment) => {
          if (comment.commentID === action.payload.commentID) {
            return {
              ...comment,
              replies: comment.replies?.map((reply) => {
                if (reply.commentID === action.payload.replyID) {
                  return {
                    ...reply,
                    likes: reply.likes.filter(
                      (like) =>
                        like.commentLikeID !== action.payload.replyLikeID
                    ),
                  };
                }
                return reply;
              }),
            };
          }
          return comment;
        }),
      };
    default:
      return activity;
  }
}

const GET_ACTIVITY = gql`
  query GetActivity($activityID: String!) {
    getActivity(request: { activityID: $activityID }) {
      activityID
      title
      description
      createdAt
      wordDifferentialArray
      secondsElapsedArray
      wordsDeleted
      wordsAdded
      secondsElapsed
      publishedAt
      imageDownloadURL
      username
      checksum
      activityLikes {
        username
        activityLikeID
      }
      comments {
        commentID
        username
        comment
        createdAt
        profileImageURL
        likes {
          username
          commentLikeID
        }
        replies {
          commentID
          username
          comment
          createdAt
          likes {
            username
            commentLikeID
          }
          profileImageURL
        }
      }
    }
  }
`;

export const ActivityContext = createContext<ActivityContextType>({
  dispatch: () => {},
  activity: null,
});

function ActivityProvider({ children }: { children: React.ReactNode }) {
  const [tasks, dispatch] = useReducer(activityReducer, null);
  const { activityID } = useParams();
  const { data } = useQuery<{
    getActivity: ActivityFeedMetadata[];
  }>(GET_ACTIVITY, {
    fetchPolicy: "no-cache",
    variables: { activityID },
  });
  useEffect(() => {
    if (data) {
      dispatch({
        type: ActivityActionType.Initialize,
        payload: data.getActivity,
      });
    }
  }, [data]);
  if (!data) {
    return <Spinner />;
  }
  return (
    <ActivityContext.Provider value={{ activity: tasks, dispatch: dispatch }}>
      {children}
    </ActivityContext.Provider>
  );
}

export default ActivityProvider;
