import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { ActivityFeedMetadata, GetFeedResponse } from "../gql/graphql";
import { gql, useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { CommentActionType } from "../components/ActivityCommentThread";

export enum FeedActionType {
  Initialize = "INITIALIZE",
  Remove = "REMOVE",
  Update = "UPDATE",
  SetGlobal = "SET_GLOBAL",
  AddActivityLike = "ADD_ACTIVITY_LIKE",
  RemoveActivityLike = "REMOVE_ACTIVITY_LIKE",
  FetchMore = "FETCH_MORE",
  CurrentlyFetching = "CURRENTLY_FETCHING",
  ResetActivityList = "RESET_ACTIVITY_LIST",
}

function feedReducer(
  feedItems: { activities: ActivityFeedMetadata[]; hasMore: boolean },
  action: { type: FeedActionType | CommentActionType; payload: any }
) {
  switch (action.type) {
    case FeedActionType.Initialize:
      return action.payload;
    case FeedActionType.ResetActivityList:
      return {
        activities: [],
        hasMore: false,
      };
    case FeedActionType.Remove:
      const filteredActivities = feedItems.activities.filter(
        (item) => item.activityID !== action.payload
      );
      return {
        ...feedItems,
        activities: filteredActivities,
      };
    case FeedActionType.Update:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              title:
                action.payload.title === undefined
                  ? item.title
                  : action.payload.title,
              description:
                action.payload.description === undefined
                  ? item.description
                  : action.payload.description,
            };
          }
          return item;
        }),
      };
    case FeedActionType.AddActivityLike:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            const newLikes = item.activityLikes;
            if (
              !newLikes.find(
                (like) => like.username === action.payload.username
              )
            ) {
              newLikes.push({
                username: action.payload.username,
                activityLikeID: action.payload.activityLikeID,
              });
            }
            return {
              ...item,
              activityLikes: newLikes,
            };
          }
          return item;
        }),
      };
    case FeedActionType.RemoveActivityLike:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              activityLikes: item.activityLikes.filter(
                (like) => like.activityLikeID !== action.payload.activityLikeID
              ),
            };
          }
          return item;
        }),
      };
    case CommentActionType.AddActivityComment:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              comments: [
                ...item.comments,
                {
                  commentID: action.payload.commentID,
                  username: action.payload.username,
                  comment: action.payload.comment,
                  createdAt: new Date(),
                  profileImageURL: action.payload.profileImageURL,
                  likes: [],
                  replies: [],
                },
              ],
            };
          }
          return item;
        }),
      };
    case CommentActionType.RemoveActivityComment:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              comments: item.comments.filter(
                (comment) => comment.commentID !== action.payload.commentID
              ),
            };
          }
          return item;
        }),
      };
    case CommentActionType.UpdateActivityComment:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              comments: item.comments.map((comment) => {
                if (comment.commentID === action.payload.commentID) {
                  return {
                    ...comment,
                    comment: action.payload.comment,
                  };
                }
                return comment;
              }),
            };
          }
          return item;
        }),
      };
    case CommentActionType.AddActivityCommentLike:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              comments: item.comments.map((comment) => {
                if (comment.commentID === action.payload.commentID) {
                  return {
                    ...comment,
                    likes: [
                      {
                        username: action.payload.username,
                        commentLikeID: action.payload.commentLikeID,
                      },
                      ...comment.likes,
                    ],
                  };
                }
                return comment;
              }),
            };
          }
          return item;
        }),
      };
    case CommentActionType.RemoveActivityCommentLike:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              comments: item.comments.map((comment) => {
                if (comment.commentID === action.payload.commentID) {
                  return {
                    ...comment,
                    likes: comment.likes.filter(
                      (like) =>
                        like.commentLikeID !== action.payload.commentLikeID
                    ),
                  };
                }
                return comment;
              }),
            };
          }
          return item;
        }),
      };
    case CommentActionType.ReplyToComment:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              comments: item.comments.map((comment) => {
                if (comment.commentID === action.payload.commentID) {
                  return {
                    ...comment,
                    replies: [
                      ...(comment.replies ?? []),
                      {
                        commentID: action.payload.replyID,
                        username: action.payload.username,
                        comment: action.payload.comment,
                        createdAt: new Date(),
                        profileImageURL: action.payload.profileImageURL,
                        likes: [],
                      },
                    ],
                  };
                }
                return comment;
              }),
            };
          }
          return item;
        }),
      };
    case CommentActionType.DeleteReplyToComment:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              comments: item.comments.map((comment) => {
                if (comment.commentID === action.payload.commentID) {
                  return {
                    ...comment,
                    replies: comment.replies?.filter(
                      (reply) => reply.commentID !== action.payload.replyID
                    ),
                  };
                }
                return comment;
              }),
            };
          }
          return item;
        }),
      };
    case CommentActionType.LikeReplyToComment:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              comments: item.comments.map((comment) => {
                if (comment.commentID === action.payload.commentID) {
                  return {
                    ...comment,
                    replies: comment.replies?.map((reply) => {
                      if (reply.commentID === action.payload.replyID) {
                        return {
                          ...reply,
                          likes: [
                            {
                              username: action.payload.username,
                              replyLikeID: action.payload.replyLikeID,
                            },
                            ...reply.likes,
                          ],
                        };
                      }
                      return reply;
                    }),
                  };
                }
                return comment;
              }),
            };
          }
          return item;
        }),
      };
    case CommentActionType.UnlikeReplyToComment:
      return {
        ...feedItems,
        activities: feedItems.activities.map((item) => {
          if (item.activityID === action.payload.activityID) {
            return {
              ...item,
              comments: item.comments.map((comment) => {
                if (comment.commentID === action.payload.commentID) {
                  return {
                    ...comment,
                    replies: comment.replies?.map((reply) => {
                      if (reply.commentID === action.payload.replyID) {
                        return {
                          ...reply,
                          likes: reply.likes.filter(
                            (like) =>
                              like.commentLikeID !== action.payload.replyLikeID
                          ),
                        };
                      }
                      return reply;
                    }),
                  };
                }
                return comment;
              }),
            };
          }
          return item;
        }),
      };
    case FeedActionType.FetchMore:
      return {
        ...feedItems,
        activities: [...feedItems.activities, ...action.payload.activities],
        hasMore: action.payload.hasMore,
      };
    case FeedActionType.CurrentlyFetching:
      return {
        ...feedItems,
        hasMore: false,
      };
    default:
      return feedItems;
  }
}

export type FeedContextType = {
  feed: GetFeedResponse;
  dispatch: React.Dispatch<{
    type: FeedActionType | CommentActionType;
    payload: any;
  }>;
  fetchMore: () => Promise<void>;
};

export const FeedContext = createContext<FeedContextType>({
  feed: { activities: [], hasMore: false },
  dispatch: () => {},

  fetchMore: async () => {},
});

const GET_FEED = gql`
  query GetFeed($username: String, $global: Boolean!, $after: DateTime) {
    getFeed(request: { username: $username, global: $global, after: $after }) {
      activities {
        activityID
        title
        description
        createdAt
        wordDifferentialArray
        secondsElapsedArray
        wordsDeleted
        wordsAdded
        secondsElapsed
        publishedAt
        imageDownloadURL
        username
        checksum
        activityLikes {
          username
          activityLikeID
        }
        comments {
          commentID
          username
          comment
          createdAt
          profileImageURL
          likes {
            username
            commentLikeID
          }
          replies {
            commentID
            username
            comment
            createdAt
            likes {
              username
              commentLikeID
            }
            profileImageURL
          }
        }
      }
      hasMore
    }
  }
`;

function FeedProvider({
  children,
  global = false,
}: {
  children: React.ReactNode;
  global?: boolean;
}) {
  const [tasks, dispatch] = useReducer(feedReducer, []);
  const [initialized, setInitialized] = useState(false);
  const lastPublishedAt = useMemo(() => {
    if (!tasks || !tasks.activities || tasks.activities.length === 0) {
      return undefined;
    }
    return tasks.activities[tasks.activities.length - 1]?.publishedAt;
  }, [tasks]);
  const { username } = useParams();
  const [getFeedQuery, { refetch, data }] = useLazyQuery<{
    getFeed: GetFeedResponse;
  }>(GET_FEED, {
    fetchPolicy: "no-cache",
    variables: { username, global, after: lastPublishedAt },
  });

  // if we're fetching more, we should dispatch the fetch more action
  const fetchMore = useCallback(async () => {
    dispatch({
      type: FeedActionType.CurrentlyFetching,
      payload: null,
    });
    const { data: refetchedData } = await refetch();

    if (data) {
      dispatch({
        type: FeedActionType.FetchMore,
        payload: refetchedData.getFeed,
      });
    }
  }, [refetch, data]);

  // used to reload the feed when the username or the global flag changes.
  useEffect(() => {
    dispatch({ type: FeedActionType.ResetActivityList, payload: null });
    setInitialized(false);
  }, [global, username]);

  // if initialized is false, fetch the feed.
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      getFeedQuery().then(({ data }) => {
        if (data?.getFeed) {
          dispatch({
            type: FeedActionType.Initialize,
            payload: data.getFeed,
          });
        }
      });
    }
  }, [data, getFeedQuery, initialized]);
  return (
    <FeedContext.Provider
      value={{ feed: tasks, dispatch: dispatch, fetchMore }}
    >
      {children}
    </FeedContext.Provider>
  );
}

export default FeedProvider;
