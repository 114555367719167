import { useEffect, useMemo, useState } from "react";
import UnauthenticatedHomePageHeader from "../components/UnauthenticatedHomePageHeader";
import { useInterval } from "../hooks/useInterval";
import TimerCard from "../components/homepageElements/TimerCard";
import LandingPageProfile from "../assets/landing-page-profile.png";
import LandingPageWritingSessionChat from "../assets/landing-page-writing-session.png";
import LandingPageEncryption from "../assets/landing-page-encryption.png";
import LandingPageActivityGrid from "../assets/landing-page-activity-grid.png";
import LandingPageStatTimer from "../assets/landing-page-stat-timer.png";
import { Button, Image } from "@nextui-org/react";
import HomepageDivider from "../components/homepageElements/HomepageDivider";
import { ArrowRight } from "iconsax-react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

const INITIAL_BANNER_LETTERS = "Write more to".split("");
const FINAL_BANNER_LETTERS = "Write more,together".split("");

const INITIAL_BANNER_KEYFRAMES = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 13, 13, 13, 12, 11,
];
const FINAL_BANNER_KEYFRAMES = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

const FIRST_LINE_LENGTH = 11;

export const TYPE_SPEED = 100;

const FEATURE_BORDER_COLOR = "#dedede";

function RefreshedHome() {
  const navigate = useNavigate();

  const [useFinalBanner, setUseFinalBanner] = useState(false);
  const [keyframeIndex, setKeyframeIndex] = useState(0);
  const intervalSatisfied = useMemo(() => {
    if (useFinalBanner) {
      return keyframeIndex === FINAL_BANNER_KEYFRAMES.length;
    }
    return keyframeIndex === INITIAL_BANNER_KEYFRAMES.length;
  }, [keyframeIndex, useFinalBanner]);
  const [activityComplete, setActivityComplete] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 750px)" });
  useInterval(
    () => {
      if (!activityComplete) {
        setActivityComplete(true);
      }
    },
    !activityComplete ? TYPE_SPEED * 28 : null
  );
  const bannerString = useMemo(() => {
    if (useFinalBanner) {
      return FINAL_BANNER_LETTERS.slice(
        0,
        FINAL_BANNER_KEYFRAMES[keyframeIndex]
      );
    }
    if (keyframeIndex === INITIAL_BANNER_KEYFRAMES.length) {
      setUseFinalBanner(true);
      setKeyframeIndex(0);
    }
    return INITIAL_BANNER_LETTERS.slice(
      0,
      INITIAL_BANNER_KEYFRAMES[keyframeIndex]
    );
  }, [keyframeIndex, useFinalBanner]);

  // incrementing activity timer
  useInterval(
    () => {
      setKeyframeIndex(keyframeIndex + 1);
    },
    !intervalSatisfied ? TYPE_SPEED : null
  );

  return (
    <div>
      <UnauthenticatedHomePageHeader />
      {/* Above the fold 100vh section */}
      <div
        className="flex flex-row"
        style={{
          height: isMobile ? undefined : "calc(100vh - 57px)",
          justifyContent: isMobile ? "center" : "flex-start",
          alignItems: "center",
        }}
      >
        {isMobile && (
          <div style={{ paddingTop: 80, paddingBottom: 80 }}>
            <p
              className="font-sans"
              style={{ fontSize: 40, textAlign: "center" }}
            >
              Write more, together
            </p>
            <div
              className="flex flex-col items-center"
              style={{ marginTop: 24 }}
            >
              <Button
                className="font-sans"
                color="primary"
                endContent={<ArrowRight />}
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Get started
              </Button>
            </div>
          </div>
        )}
        {!isMobile && (
          <div
            style={{ flex: 1, maxWidth: "50%", height: "100%" }}
            className="flex flex-col justify-center items-center relative"
          >
            <div className="flex flex-row justify-start">
              {bannerString.slice(0, FIRST_LINE_LENGTH).map((letter, index) => (
                <div
                  key={`line1-letter-${index}`}
                  style={{
                    borderRightWidth: index === bannerString.length - 1 ? 1 : 0,
                    borderColor: "black",
                    display: "inline-block",
                  }}
                >
                  <pre
                    className="font-sans"
                    style={{
                      fontSize: 60,
                    }}
                  >
                    {letter}
                  </pre>
                </div>
              ))}
            </div>
            {bannerString.length > FIRST_LINE_LENGTH && (
              <div className="flex flex-row">
                {bannerString.slice(FIRST_LINE_LENGTH).map((letter, index) => (
                  <div
                    key={`line2-letter-${index}`}
                    style={{
                      borderRightWidth:
                        index === bannerString.length - FIRST_LINE_LENGTH - 1 &&
                        !activityComplete
                          ? 1
                          : 0,
                      borderColor: "black",
                      display: "inline-block",
                    }}
                  >
                    <pre
                      className="font-sans"
                      style={{
                        fontSize: 60,
                      }}
                    >
                      {letter}
                    </pre>
                  </div>
                ))}
              </div>
            )}
            {bannerString.length <= FIRST_LINE_LENGTH && (
              <div className="flex flex-row">
                <p style={{ fontSize: 60, color: "white" }}>.</p>
              </div>
            )}
            {activityComplete && (
              <motion.div
                style={{ marginTop: 40 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <Button
                  className="font-sans"
                  color="primary"
                  endContent={<ArrowRight />}
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  Get started
                </Button>
              </motion.div>
            )}
            {!activityComplete && <div style={{ height: 80 }} />}
          </div>
        )}
        {!isMobile && (
          <div
            className="flex flex-col items-center justify-center"
            style={{ flex: 1 }}
          >
            <div>
              <TimerCard />
            </div>
          </div>
        )}
      </div>
      <HomepageDivider />
      {/* Your writing home section */}
      <div className="flex flex-col items-center" style={{ marginTop: 80 }}>
        <div
          className="flex flex-row justify-center"
          style={{ marginBottom: 40 }}
        >
          <p className="font-sans" style={{ fontSize: isMobile ? 30 : 40 }}>
            Your writing home
          </p>
        </div>

        <div
          style={{
            width: "80%",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
            marginBottom: 40,
          }}
        >
          <Image src={LandingPageProfile} alt="landing page profile" />
        </div>
      </div>
      {/* Mission statement section */}
      <div
        className="flex flex-col items-center"
        style={{ marginBottom: 120, marginTop: 80 }}
      >
        <div
          style={{
            width: "80%",
            // maxWidth: 500,
            padding: 40,
            backgroundColor: "#7194A8",
          }}
        >
          <div
            className="flex flex-row justify-center"
            style={{ marginBottom: 40 }}
          >
            <div
              style={{
                borderBottomWidth: 1,
                borderBottomColor: "rgba(255, 255, 255, 0.3)",
                paddingBottom: 24,
              }}
            >
              <p
                className="font-sans"
                style={{ fontSize: isMobile ? 30 : 40, color: "white" }}
              >
                Why we exist
              </p>
            </div>
          </div>

          <div
            className="font-sans"
            style={{
              lineHeight: 1.5,
              color: "white",
            }}
          >
            <p
              style={{ fontSize: isMobile ? 16 : 24 }}
            >{`Draft Zero is a celebration of the writing process.
            We're here for everything from the long sessions when you get writer's block,
            to the times you spend an hour poring over the wording of a single paragraph,
            to those moments when you are just trying to organize your thoughts.`}</p>
            <div style={{ marginTop: 24 }}>
              <p
                style={{ fontSize: isMobile ? 16 : 24 }}
              >{`Writing is always hard, but when we write together, 
              it can be just a little bit easier.`}</p>
            </div>
          </div>
        </div>
      </div>
      {/* Features section */}
      <div className="flex flex-col items-center">
        <div
          className="flex justify-center"
          style={{
            maxWidth: "80%",
            flexDirection: isMobile ? "column" : "row",
            borderRightWidth: isMobile ? 1 : 0,
            borderLeftWidth: isMobile ? 1 : 0,
            borderColor: FEATURE_BORDER_COLOR,
          }}
        >
          <div
            className="flex flex-col"
            style={{
              flex: 2,
              borderWidth: 1,
              borderColor: FEATURE_BORDER_COLOR,
              borderRightWidth: isMobile ? 0 : 1,
              borderLeftWidth: isMobile ? 0 : 1,
              display: "flex",
              flexDirection: isMobile ? "column-reverse" : "column",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <div style={{ paddingTop: 20 }}>
              <img
                src={LandingPageWritingSessionChat}
                alt="writing session chat screenshot"
              />
            </div>
            {isMobile && (
              <div
                style={{
                  borderColor: FEATURE_BORDER_COLOR,
                  borderWidth: 1,
                  borderBottomWidth: 0,
                  width: "60%",
                  marginTop: 12,
                }}
              />
            )}
            <div
              style={{
                marginTop: 12,
                padding: 20,
              }}
            >
              <p
                className="font-sans"
                style={{ fontSize: 30, fontWeight: "bold" }}
              >
                Share your writing sessions
              </p>
              <p className="font-sans">Follow and support your writing pals.</p>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              borderWidth: 1,
              borderLeftWidth: 0,
              borderRightWidth: isMobile ? 0 : 1,
              borderTopWidth: isMobile ? 0 : 1,
              borderColor: FEATURE_BORDER_COLOR,
              display: "flex",
              flexDirection: isMobile ? "column-reverse" : "column",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <div style={{ paddingTop: 20 }}>
              <img src={LandingPageEncryption} alt="encryption screenshot" />
            </div>
            {isMobile && (
              <div
                style={{
                  borderColor: FEATURE_BORDER_COLOR,
                  borderWidth: 1,
                  borderBottomWidth: 0,
                  width: "60%",
                  marginTop: 12,
                }}
              />
            )}
            <div
              style={{
                marginTop: 12,
                padding: 20,
              }}
            >
              <p
                className="font-sans"
                style={{ fontSize: 30, fontWeight: "bold" }}
              >
                Write privately
              </p>
              <p className="font-sans">
                All writing on Draft Zero is encrypted locally. No one besides
                you, not even the Draft Zero team, can read your writing.
              </p>
            </div>
          </div>
        </div>
        <div
          className="flex justify-center"
          style={{
            marginBottom: 80,
            maxWidth: "80%",
            flexDirection: isMobile ? "column" : "row",
            borderRightWidth: isMobile ? 1 : 0,
            borderLeftWidth: isMobile ? 1 : 0,
            borderColor: FEATURE_BORDER_COLOR,
          }}
        >
          <div
            style={{
              flex: 1,
              borderWidth: 1,
              borderColor: FEATURE_BORDER_COLOR,
              borderTopWidth: 0,
              borderRightWidth: isMobile ? 0 : 1,
              borderLeftWidth: isMobile ? 0 : 1,
              display: "flex",
              flexDirection: isMobile ? "column-reverse" : "column",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <div style={{ paddingTop: 20 }}>
              <img
                src={LandingPageActivityGrid}
                alt="activity grid screenshot"
              />
            </div>
            {isMobile && (
              <div
                style={{
                  borderColor: FEATURE_BORDER_COLOR,
                  borderWidth: 1,
                  borderBottomWidth: 0,
                  width: "60%",
                  marginTop: 12,
                }}
              />
            )}
            <div
              style={{
                marginTop: 12,
                padding: 20,
              }}
            >
              <p
                className="font-sans"
                style={{ fontSize: 30, fontWeight: "bold" }}
              >
                Track activity
              </p>
              <p className="font-sans">
                Accumulated stats all time and per year.
              </p>
            </div>
          </div>
          <div
            className="flex flex-col"
            style={{
              flex: 2,
              borderWidth: 1,
              borderColor: FEATURE_BORDER_COLOR,
              borderLeftWidth: 0,
              borderTopWidth: 0,
              borderRightWidth: isMobile ? 0 : 1,
              display: "flex",
              flexDirection: isMobile ? "column-reverse" : "column",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <div style={{ paddingTop: 20 }}>
              <img src={LandingPageStatTimer} alt="stat timer screenshot" />
            </div>
            {isMobile && (
              <div
                style={{
                  borderColor: FEATURE_BORDER_COLOR,
                  borderWidth: 1,
                  borderBottomWidth: 0,
                  width: "60%",
                  marginTop: 12,
                }}
              />
            )}
            <div
              style={{
                marginTop: 12,
                padding: 20,
              }}
            >
              <p
                className="font-sans"
                style={{ fontSize: 30, fontWeight: "bold" }}
              >
                Automatically track your stats
              </p>
              <p className="font-sans">All you need to do is write.</p>
            </div>
          </div>
        </div>
      </div>
      {/* CTA section */}
      <div style={{ paddingBottom: 160 }}>
        <div
          className="flex flex-row justify-center"
          style={{ marginBottom: 40, marginTop: 80 }}
        >
          <p className="font-sans" style={{ fontSize: isMobile ? 30 : 40 }}>
            Ready to write?
          </p>
        </div>
        <div className="flex flex-row justify-center">
          <Link to="/signup">
            <Button
              className="font-sans"
              color="primary"
              endContent={<ArrowRight />}
            >
              Get started
            </Button>
          </Link>
        </div>
      </div>
      {/* Footer section */}
      <div style={{ borderTopWidth: 1, borderColor: FEATURE_BORDER_COLOR }}>
        <div
          className="flex flex-row justify-between items-center"
          style={{ padding: 20 }}
        >
          <div>
            <p className="font-sans" style={{ fontSize: 12 }}>
              Draft Zero © 2024
            </p>
          </div>
          <div className="font-sans" style={{ fontSize: 12 }}>
            <p>
              <span>support@draftzero.com</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefreshedHome;
