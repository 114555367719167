import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  decryptionKey: string | null;
  userID?: string;
  username?: string;
  profilePhotoDownloadURL?: string;
  firstName?: string;
  lastName?: string;
  bio?: string;
  link?: string;
}

const initialUserState: UserState = {
  decryptionKey: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUserInfo: (state, action) => {
      state.decryptionKey = action.payload.decryptionKey;
      state.userID = action.payload.userID;
      state.username = action.payload.username;
      state.profilePhotoDownloadURL = action.payload.profilePhotoDownloadURL;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.bio = action.payload.bio;
      state.link = action.payload.link;
    },
    clearUserInfo: (state) => {
      state.decryptionKey = null;
      state.userID = undefined;
      state.username = undefined;
      state.profilePhotoDownloadURL = undefined;
      state.firstName = undefined;
      state.lastName = undefined;
      state.bio = undefined;
      state.link = undefined;
    },
    updatePhotoDownloadURL: (state, action) => {
      state.profilePhotoDownloadURL = action.payload;
    },
    updateFromSettings: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.bio = action.payload.bio;
      state.username = action.payload.username;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserInfo,
  clearUserInfo,
  updatePhotoDownloadURL,
  updateFromSettings,
} = userSlice.actions;

export default userSlice.reducer;
