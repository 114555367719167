import { AddSquare, CloseCircle, User } from "iconsax-react";
import EarthBackground from "../assets/earth-background.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoomPresets } from "../gql/graphql";
export default function RoomsGrid() {
  const [createNewHovered, setCreateNewHovered] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="w-full" style={{ marginTop: 20, marginLeft: 64 }}>
      <div className="flex flex-col items-start">
        <div>
          <p className="font-sans text-xl" style={{ fontWeight: "bold" }}>
            Public rooms
          </p>
        </div>
        <div style={{ marginTop: 20 }}>
          <div
            className="flex flex-col items-center justify-end cursor-pointer"
            style={{
              width: 200,
              height: 200,
              backgroundImage: `url(${EarthBackground})`,
              backgroundSize: "cover",
              borderRadius: 10,
            }}
            onClick={() => {
              navigate(`/room/${RoomPresets.TheEveryoneRoom}`);
            }}
          >
            <div
              className="flex flex-col blurred-div"
              style={{
                margin: 10,
                borderRadius: 5,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                width: 180,
              }}
            >
              <p
                className="font-sans text-white"
                style={{ fontWeight: "bold", fontSize: 12 }}
              >
                The everyone room
              </p>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center">
                  <div style={{ marginRight: 5 }}>
                    <CloseCircle variant="Bold" color="#858585" size={10} />
                  </div>
                  <p
                    className="font-sans"
                    style={{ fontSize: 8, color: "#858585" }}
                  >
                    PROMPT
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  <div style={{ marginRight: 2 }}>
                    <p
                      className="font-sans"
                      style={{ color: "white", fontSize: 8 }}
                    >
                      0
                    </p>
                  </div>
                  <User color="white" size={10} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start" style={{ marginTop: 50 }}>
        <div>
          <p className="font-sans text-xl" style={{ fontWeight: "bold" }}>
            Create your own room
          </p>
        </div>
        <div style={{ marginTop: 20 }}>
          <div
            onMouseEnter={() => setCreateNewHovered(true)}
            onMouseLeave={() => setCreateNewHovered(false)}
            className="flex flex-col items-center justify-center"
            style={{
              width: 200,
              height: 200,
              backgroundColor: createNewHovered ? "#7194A8" : "#e0e0e0",
              borderRadius: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/create-room");
            }}
          >
            <AddSquare color={"white"} size={80} style={{ strokeWidth: 0.5 }} />
            <p
              className="font-sans"
              style={{
                color: "white",
                fontSize: 12,
              }}
            >
              Create new room
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
