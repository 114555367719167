import {
  Autocomplete,
  AutocompleteItem,
  Avatar,
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Textarea,
} from "@nextui-org/react";
import Header from "./Header";
import { ArrowLeft, MessageQuestion, SearchNormal1 } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import NotificationBell from "./NotificationBell";
import { useMediaQuery } from "react-responsive";

const SEARCH_FOR_USERS = gql`
  query SearchForUsers($query: String!) {
    searchForUsers(request: { query: $query }) {
      username
      profilePhotoURL
    }
  }
`;

const SEND_FEEDBACK = gql`
  mutation SendFeedback($title: String!, $description: String!) {
    sendFeedback(request: { title: $title, description: $description }) {
      success
    }
  }
`;

function SearchBarHeader() {
  const [searchQuery, setSearchQuery] = useState("");
  const [feedbackTitle, setFeedbackTitle] = useState("");
  const [feedbackDescription, setFeedbackDescription] = useState("");
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [sendFeedback, { loading }] = useMutation(SEND_FEEDBACK, {
    variables: { title: feedbackTitle, description: feedbackDescription },
    onCompleted: () => {
      setFeedbackTitle("");
      setFeedbackDescription("");
      setFeedbackSent(true);
    },
  });
  const isSmallMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState<
    {
      username: string;
      profilePhotoURL: string | undefined;
    }[]
  >([]);
  const [searchForUsers, { loading: searchDataLoading }] = useLazyQuery(
    SEARCH_FOR_USERS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setSearchResults(data.searchForUsers);
      },
    }
  );

  const debouncedSearchForUsers = useDebouncedCallback(searchForUsers, 100);

  useEffect(() => {
    if (searchQuery) {
      debouncedSearchForUsers({ variables: { query: searchQuery } });
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchForUsers, searchQuery]);

  const itemList = useMemo(() => {
    if (searchQuery === "") {
      return [];
    }
    if (searchDataLoading) {
      return [
        <AutocompleteItem key="loading" textValue="loading">
          <div className="flex items-center">Loading...</div>
        </AutocompleteItem>,
      ];
    }
    if (searchResults.length === 0) {
      return [
        <AutocompleteItem key="no-results" textValue="no results">
          <div className="flex items-center">No results found</div>
        </AutocompleteItem>,
      ];
    }
    return searchResults.map((result) => (
      <AutocompleteItem key={result.username} textValue={result.username}>
        <div
          className="flex items-center"
          onClick={() => {
            navigate(`/writer/${result.username}`);
          }}
        >
          <Avatar
            src={result.profilePhotoURL}
            size="sm"
            alt={result.username}
          />
          <p className="ml-2">{result.username}</p>
        </div>
      </AutocompleteItem>
    ));
  }, [navigate, searchDataLoading, searchQuery, searchResults]);

  return (
    <Header>
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col ml-2 font-sans" style={{ width: "100%" }}>
          <Autocomplete
            aria-labelledby="search-bar-input"
            placeholder="Search for writers"
            inputValue={searchQuery}
            onInputChange={(value) => setSearchQuery(value)}
            allowsCustomValue={true}
            disabledKeys={["no-results", "loading"]}
            startContent={<SearchNormal1 />}
            selectorIcon={null}
            onKeyDown={(e) => {
              if ("continuePropagation" in e) {
                e.continuePropagation();
              }
            }}
            onSelectionChange={() => {}}
            inputProps={{
              classNames: {
                inputWrapper: ["h-2.5"],
              },
            }}
            selectorButtonProps={{
              style: { display: "none" },
            }}
            selectedKey={null}
            menuTrigger="focus"
            defaultFilter={(item, query) => {
              return true;
            }}
          >
            {itemList}
          </Autocomplete>
        </div>

        <div className="flex flex-row items-center">
          <div style={{ marginRight: 20 }}>
            <Popover
              onClose={() => {
                setFeedbackSent(false);
              }}
            >
              <PopoverTrigger>
                <Button isIconOnly variant="light">
                  <MessageQuestion />
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <div
                  className="flex flex-col"
                  style={{ width: 200, height: 320 }}
                >
                  <div style={{ marginTop: 10 }}>
                    <p className="font-sans font-semibold">Send feedback</p>
                  </div>
                  {!feedbackSent && (
                    <>
                      <div
                        className="font-sans"
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        <Input
                          placeholder="title"
                          size="sm"
                          variant="bordered"
                          value={feedbackTitle}
                          onValueChange={(value) => setFeedbackTitle(value)}
                        />
                      </div>
                      <div className="font-sans" style={{ marginBottom: 10 }}>
                        <Textarea
                          size="sm"
                          variant="bordered"
                          placeholder="description"
                          className="flex-1 h-full font-sans"
                          minRows={30}
                          value={feedbackDescription}
                          onValueChange={(value) =>
                            setFeedbackDescription(value)
                          }
                        />
                      </div>
                      <Button
                        size="sm"
                        onClick={async () => {
                          if (feedbackTitle) {
                            await sendFeedback();
                          }
                        }}
                        isLoading={loading}
                      >
                        Submit
                      </Button>
                    </>
                  )}
                  {feedbackSent && (
                    <div
                      className="font-sans flex flex-col flex-1"
                      style={{ marginTop: 10 }}
                    >
                      <div className="flex-1 flex flex-col justify-center items-center">
                        <p>Thank you for the feedback!</p>
                      </div>
                      <Button
                        size="sm"
                        className="font-sans"
                        color="primary"
                        startContent={<ArrowLeft />}
                        onClick={() => setFeedbackSent(false)}
                      >
                        Send more feedback
                      </Button>
                    </div>
                  )}
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <NotificationBell />
        </div>
      </div>
    </Header>
  );
}

export default SearchBarHeader;
